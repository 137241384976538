import { useEffect, useState } from "react";
import styled from "styled-components";

const Component = styled.img`
    margin: ${props => props.centered ? '0 auto' : '0'};
    border-radius: .25rem;
    width: ${props => props.width};
    height: ${props => props.height};
    object-fit: cover;
`;

export default function Image(props) {
    const [src, setSrc] = useState(null);
    useEffect(() => {
        let obj;
        if (props.data !== null) {
            if (props.data instanceof File) {
                obj = URL.createObjectURL(props.data);
                setSrc(obj);
            }
            else {
                setSrc(props.data);
            }
        }

        return () => URL.revokeObjectURL(obj)
    }, [props.data])

    return <Component src={src} centered={props.centered !== undefined} width={props.width || '400px'} height={props.height || '200px'}/>
}