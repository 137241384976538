import styled from "styled-components";
import Diary from "./diary";
import Weight from "../../components/weight";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Modals, Paths } from "../../constants";
import { dataStore } from "../../store/data";
import { observable } from "mobx";
import { observer } from "mobx-react";
import global from "../../store/global";
import { declension } from "../../utils";

const Component = styled.div`
    padding: 1rem;
    overflow: overlay;
`;
const RowSection = styled.div`
    flex-direction: row;
    gap: 2rem;
    display: flex;
    width: 100%;
`;
const DiariesList = styled.div`
    flex-direction: row;
    gap: 1rem;
    display: flex;
    overflow: auto;
    flex-wrap: wrap;
    justify-content: flex-start;
`;

function Diaries(props) {
    const { diaries, currentWeight, weights } = dataStore;
    console.log(diaries);
    const navigate = useNavigate();

    function openDiary(diary) {
        navigate(Paths.diary+diary?.id);
    }

    return <Component>
        <h1>За все время ты провел {diaries?.length} {declension(diaries?.length, ['тренировку', 'тренировки', 'тренировок'])}</h1>
        <div style={{display: ' flex', alignItems: 'center', gap: '1rem'}}>
            <h2>Твой текущий вес {currentWeight}</h2>
            <button onClick={() => global.setModal(Modals.create_weight.name, null)}>Изменить вес</button>
        </div>
        <RowSection>
            <div style={{flex: '2'}}>
            <div style={{display: ' flex', alignItems: 'center', gap: '1rem'}}>
                <h1>Дневники</h1>
                <button onClick={() => global.setModal(Modals.create_diary.name, null)}>Добавить</button>
                </div>
                <DiariesList>
                {
                    diaries.map(diary => <Diary data={diary} photosCount={diary.photos.length} onClick={() => openDiary(diary)}/>)
                }
                </DiariesList>
            </div>
            <div style={{flex: '1'}}>
                <h1>История веса</h1>
                <div style={{flexDirection: 'column-reverse', display: 'flex', gap: '.5rem'}}>
                {
                    weights.map(weight => <Weight key={weight.id} data={weight}/>)
                }
                </div>
            </div>
        </RowSection>
    </Component>
}

export default observer(Diaries);