import { useEffect, useRef, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { declension } from '../../utils';
import { Colors } from "../../constants";
import { observer } from "mobx-react";
import Image from "../../components/image_preview";
import Button from "../../components/button";
import { dataStore } from "../../store/data";
import Imag from '../../store/images';
import { Config } from "../../constants";
import { toast } from "react-toastify";
import { v4 } from 'uuid';
const Component = styled.div`
    position: relative;
    align-items: center;
    flex-direction: column;
    display: flex;
    height: 100%;
    gap: 1rem;
`;
const Row = styled.div`
    display: flex;
`;
const EmojiButton = styled.button`
    justify-content: center;
    display: flex;
    border: none;
    border-radius: .5rem;
    padding: .5rem;
    font-size: 2rem;
    background: none;
    outline: ${props => props.selected ? '2px solid '+Colors.primary : 'none'};
    cursor: pointer;

    &:hover {
        background-color: ${Colors.primary};
    }

    &:focus {
        outline: 2px solid ${Colors.primary};
    }
`;
const InputContainer = styled.button`
    position: relative;
    //margin: 0 auto;
    border: 1px solid ${Colors.primary};
    border-radius: .25rem;
    width: fit-content;
    padding: .5rem;
    background: ${Colors.primary};
    color: white;
    cursor: pointer;
`;
const ImageInput = styled.input`
    width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
`;
const PhotoList = styled.div`
    padding: 1rem;
    flex-direction: row;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
`;
function Finish({next, id, workoutId}) {
    const navigate = useNavigate();
    function finish(feel) {
        next(feel);
    }

    function addPhoto(photo) {
        let p = [...photos];
        p.push(photo);
        setPhotos(p);
    }

    function removePhoto(index) {
        let p = [...photos];
        p.splice(index, 1);
        setPhotos(p);
    }

    const [photos, setPhotos] = useState([]);
    
    async function save() {
        try {
            const response = await dataStore.update(Config.DIARIES, {
                id: id,
                workoutId: workoutId,
                feel: feel,
                isFinished: true
            });
            if (!response.data.status) {
                toast.error(response.data.message);
                return;
            }
        }
        catch (error) {
            toast.error(error?.response?.data?.message || error.message);
            return;
        };

        if (photos.length > 0) {
            try {
                let coverId = null;
                let data = new FormData();
                for (let photo of photos) {
                    data = new FormData();
                    coverId = v4();
                    data.append('file',photo);
                    data.append('id', coverId);
                    const response = await Imag.upload(data);
                    if (!response.data.status) {
                        toast.error(response.data.message);
                        return;
                    }

                    data = new FormData();
                    data.append('diaryId', id);
                    data.append('imageId', coverId);
                    data.append('photoComment', '');

                    const stepResponse = await dataStore.create(Config.DIARYPHOTOS, data);
                    if (!stepResponse.data.status) {
                        toast.error(stepResponse.data.message);
                        return;
                    }
                }
            }
            catch (error) {
                toast.error(error?.response?.data?.message || error.message);
                return;
            };
        }

        await dataStore.fetch();
        navigate('/');
    }
    const [feel, setFeel] = useState(null); 
    const feelRef = useRef(null); // создаем объект useRef
    useEffect(() => { // обновляем feelRef.current при изменении feel 
        feelRef.current = feel; 
    }, [feel]);

    return <Component>
        <h1 style={{fontSize: '4rem'}}>Тренировка завершена!🥳</h1>
        <div>
        <p>Как себя чувствуешь?</p>
        <Row>
            <EmojiButton onClick={() => setFeel(-1)}>🫠</EmojiButton>
            <EmojiButton onClick={() => setFeel(0)}>🙂</EmojiButton>
            <EmojiButton onClick={() => setFeel(1)}>😏</EmojiButton>
        </Row>
        </div>
        {
            photos.length < 5 && <InputContainer>
                <ImageInput type='file' id='cover' onChange={(event) => addPhoto(event.target.files[0])}/>
                <label for='cover'>Добавить фотографии в дневник</label>
            </InputContainer>
        }
        
        <PhotoList>
        {
            photos.length > 0 && photos.map((photo, index) => <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                <Image data={photo} width='200px' height='200px'/>    
                <span onClick={() => removePhoto(index)} style={{color: 'red', fontSize: 'smaller', cursor: 'pointer'}}>Удалить</span>
                </div>
            )
        }
        </PhotoList>
        <Button onClick={() => save()}>Завершить</Button>
    </Component>
}

export default Finish;