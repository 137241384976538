import { useEffect, useState } from 'react';
import styled from 'styled-components';
import Input from '../input_material';
import Textarea from '../textarea_material';
import Button from '../button';
import { Colors, Device } from '../../constants';
import { toast } from 'react-toastify';
import { inject } from 'mobx-react';
import { ReactComponent as ReactLogo } from "../../icons/food.svg";
import { ReactComponent as DeleteIcon } from "../../icons/delete.svg";
import Select from '../select';

const Component = styled.div`
    position: relative;
    flex-direction: column;
    gap: 1rem;
    display: flex;
    max-height: 90vh;
    padding: 1rem;
    overflow-y: auto;
    

    @media ${Device.mobileS} {
        gap: .75rem;
    }

    @media ${Device.mobileM} {
        gap: .75rem;
    }

    @media ${Device.mobileL} {
        gap: .75rem;
    }

    @media ${Device.tablet} {
        gap: .75rem;
    }

    @media ${Device.laptopS} {
        gap: .75rem;
    }

    @media ${Device.laptopM} {
        gap: .75rem;
        max-width: 70vw;
    }

    @media ${Device.laptopL} {
        gap: 1rem;
        max-width: 50vw;
    }
`;
const Loading = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.2);
`;
const LoadingText = styled.span`
    border-radius: .25rem;
    padding: 1rem;
    background-color: white;
    user-select: none;
`;

const ContentSection = styled.div`
    flex-direction: row;
    display: flex;
    gap: 2rem;
    width: 100%;

    @media ${Device.mobileS} {
        flex-direction: column;
    }

    @media ${Device.mobileM} {
        flex-direction: column;
    }

    @media ${Device.mobileL} {
        flex-direction: column;
    }

    @media ${Device.tablet} {
        flex-direction: row;
    }

    @media ${Device.laptopS} {
        flex-direction: row;
    }

    @media ${Device.laptopM} {
        flex-direction: row;
    }

    @media ${Device.laptopL} {
        flex-direction: row;
    }
`;
const MainSection = styled.div`
    flex-direction: column;
    gap: 1rem;
    display: ${props => props.isVisible ? 'flex' : 'none'};
    width: 40vw;
    /* padding: 1rem; */

    @media ${Device.mobileS} {
        flex-direction: column;
        gap: .75rem;
        width: 80vw;
    }


    @media ${Device.mobileM} {
        flex-direction: column;
        gap: .75rem;
        width: 80vw;
    }

    @media ${Device.mobileL} {
        flex-direction: column;
        gap: .75rem;
        width: 80vw;
    }

    @media ${Device.tablet} {
        flex-direction: row;
        gap: .75rem;
        width: 90vw;
    }

    @media ${Device.laptopS} {
        flex-direction: row;
        gap: .75rem;
        width: 70vw;
    }

    @media ${Device.laptopM} {
        flex-direction: row;
        gap: .75rem;
        width: 70vw;
    }

    @media ${Device.laptopL} {
        flex-direction: row;
        gap: 1rem;
        width: 40vw;
    }
`;
const AddStepSection = styled.div`
    flex-direction: column;
    gap: 0.75rem;
    display: ${props => props.isVisible ? 'flex' : 'none'};
    width: 400px;

    @media ${Device.mobileS} {
        width: 80vw;
    }

    @media ${Device.mobileM} {
        width: 80vw;
    }

    @media ${Device.mobileL} {
        width: 80vw;
    }

    @media ${Device.tablet} {
        width: 300px;
    }

    @media ${Device.laptopS} {
        width: 300px;
    }

    @media ${Device.laptopM} {
        width: 400px;
    }

    @media ${Device.laptopL} {
        width: 400px;
    }
`;
const StepSection = styled.div`
    flex-direction: column;
    gap: 0.75rem;
    display: ${props => props.isVisible ? 'flex' : 'none'};
    width: 30vw;

    @media ${Device.mobileS} {
        width: 100%;
    }

    @media ${Device.mobileM} {
        width: 100%;
    }

    @media ${Device.mobileL} {
        width: 100%;
    }

    @media ${Device.tablet} {
        width: 300px;
    }

    @media ${Device.laptopS} {
        width: 300px;
    }

    @media ${Device.laptopM} {
        width: 400px;
    }

    @media ${Device.laptopL} {
        width: 400px;
    }
`;
const FileUpload = styled.input`
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    width: 400px;
    height: 200px;
    cursor: pointer;

    @media ${Device.mobileS} {
        width: 100%;
        height: 200px;
    }

    @media ${Device.mobileM} {
        width: 100%;
        height: 200px;
    }

    @media ${Device.mobileL} {
        width: 100%;
        height: 200px;
    }

    @media ${Device.tablet} {
        width: 300px;
        height: 150px;
    }

    @media ${Device.laptopS} {
        width: 350px;
        height: 150px;
    }

    @media ${Device.laptopM} {
        width: 400px;
        height: 200px;
    }

    @media ${Device.laptopL} {
        width: 400px;
        height: 200px;
    }
`;
const FileType = styled.div`
    position: relative;
    margin: 0 auto;
    cursor: pointer;
    border: 1px solid lightgray;
    border-radius: .25rem;
    width: 400px;
    height: 200px;

    @media ${Device.mobileS} {
        width: 100%;
        height: 200px;
    }

    @media ${Device.mobileM} {
        width: 100%;
        height: 200px;
    }

    @media ${Device.mobileL} {
        width: 100%;
        height: 200px;
    }

    @media ${Device.tablet} {
        width: 300px;
        height: 150px;
    }

    @media ${Device.laptopS} {
        width: 300px;
        height: 150px;
    }

    @media ${Device.laptopM} {
        width: 400px;
        height: 200px;
    }

    @media ${Device.laptopL} {
        width: 400px;
        height: 200px;
    }
`;
const DeleteButton = styled(DeleteIcon)`
    position: ${props => props.absolute ? 'absolute' : 'relative'};
    right: ${props => props.absolute ? '.5rem' : '0'};
    top: ${props => props.absolute ? '.5rem' : '0'};
    width: 24px;
    height: 24px;
    fill: ${Colors.red};
`;
const StyledLogo = styled(ReactLogo)`
    position: absolute;
    top: 0;
    left: 0;
    height: 150px;
    width: 400px;

    @media ${Device.mobileS} {
        width: 100%;
        height: 150px;
    }

    @media ${Device.mobileM} {
        width: 100%;
        height: 150px;
    }

    @media ${Device.mobileL} {
        width: 100%;
        height: 150px;
    }

    @media ${Device.tablet} {
        width: 300px;
        height: 100px;
    }

    @media ${Device.laptopS} {
        width: 300px;
        height: 100px;
    }

    @media ${Device.laptopM} {
        width: 400px;
        height: 150px;
    }

    @media ${Device.laptopL} {
        width: 400px;
        height: 150px;
    }
`;
const ImageAlt = styled.span`
    position: absolute;
    bottom: 0.5rem;
    left: 0;
    font-family: 'Amatic SC', cursive;
    width: 400px;
    text-align: center;
    color: ${Colors.peach};
    font-size: 1.5rem;

    @media ${Device.mobileS} {
        width: 100%;
    }

    @media ${Device.mobileM} {
        width: 100%;
    }

    @media ${Device.mobileL} {
        width: 100%;
    }

    @media ${Device.tablet} {
        width: 300px;
    }

    @media ${Device.laptopS} {
        width: 300px;
    }

    @media ${Device.laptopM} {
        width: 400px;
    }

    @media ${Device.laptopL} {
        width: 400px;
    }
`;
const ImagePreview = styled.img`
    border-radius: .25rem;
    width: 400px;
    height: 200px;
    margin: ${props => props.centered !== undefined ? '0 auto' : '0'};
    object-fit: cover;

    @media ${Device.mobileS} {
        width: 100%;
        height: 200px;
    }

    @media ${Device.mobileM} {
        width: 100%;
        height: 200px;
    }

    @media ${Device.mobileL} {
        width: 100%;
        height: 200px;
    }

    @media ${Device.tablet} {
        width: 300px;
        height: 150px;
    }

    @media ${Device.laptopS} {
        width: 300px;
        height: 150px;
    }

    @media ${Device.laptopM} {
        width: 400px;
        height: 200px;
    }

    @media ${Device.laptopL} {
        width: 400px;
        height: 200px;
    }
`;
// const DeleteButton = styled.button`
//     position: absolute;
//     top: 0.5rem;
//     right: 0.5rem;
//     border: 1px solid white;
//     border-radius: .25rem;
//     padding: 0.25rem;
//     outline: none;
//     color: lightgray;
//     background-color: white;
//     font-size: smaller;
//     cursor: pointer;
//     &:hover {
//         color: ${Colors.red}
//     }
// `;
const NutrientsList = styled.div`
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: space-between;
    display: flex;

    @media ${Device.mobileS} {
        gap: .5rem;
    }

    @media ${Device.mobileM} {
        gap: .5rem;
    }

    @media ${Device.mobileL} {
        gap: .5rem;
    }

    @media ${Device.tablet} {
        gap: .5rem;
    }

    @media ${Device.laptopS} {
        gap: .5rem;
    }

    @media ${Device.laptopM} {
        gap: 1rem;
    }

    @media ${Device.laptopL} {
        gap: 1rem;
    }
`;
const NutrientItem = styled.div`
    flex-direction: column;
    justify-content: center;
    display: flex;
    flex: 1;
    border-radius: .25rem;
    border: 1px solid lightgray;
    padding: .5rem;
    min-width: 40%;
    max-width: 50%;
    text-align: center;
`;
const NutrientTitle = styled.span`
    font-size: 0.9rem;

    @media ${Device.mobileS} {
        font-size: 0.7rem;
    }

    @media ${Device.mobileM} {
        font-size: 0.7rem;
    }

    @media ${Device.mobileL} {
        font-size: 0.8rem;
    }

    @media ${Device.tablet} {
        font-size: 0.8rem;
    }

    @media ${Device.laptopS} {
        font-size: 0.8rem;
    }

    @media ${Device.laptopM} {
        font-size: 0.8rem;
    }

    @media ${Device.laptopL} {
        font-size: 0.9rem;
    }
`;
const NutrientValue = styled.span`
    font-size: 1.3rem;
    color: ${props => props.color};

    @media ${Device.mobileS} {
        font-size: .9rem;
    }

    @media ${Device.mobileM} {
        font-size: .9rem;
    }

    @media ${Device.mobileL} {
        font-size: 1rem;
    }

    @media ${Device.tablet} {
        font-size: 1rem;
    }

    @media ${Device.laptopS} {
        font-size: 1rem;
    }

    @media ${Device.laptopM} {
        font-size: 1rem;
    }

    @media ${Device.laptopL} {
        font-size: 1.2rem;
    }
`;
const SmallText = styled.span`
    font-size: smaller;
`;
const Title = styled.span`
    font-size: 1.6rem;
    font-family: 'Amatic SC', cursive;

    @media ${Device.mobileS} {
        font-size: 1.5rem;
    }


    @media ${Device.mobileM} {
        font-size: 1.5rem;
    }

    @media ${Device.mobileL} {
        font-size: 1.5rem;
    }

    @media ${Device.tablet} {
        font-size: 1.5rem;
    }

    @media ${Device.laptopS} {
        font-size: 1.5rem;
    }

    @media ${Device.laptopM} {
        font-size: 1.5rem;
    }

    @media ${Device.laptopL} {
        font-size: 1.6rem;
    }
`;
const ProductList = styled.div`
    flex-direction: column;
    gap: .25rem;
    display: flex;
`;
const ProductItem = styled.div`
    position: relative;
    flex-direction: row;
    align-items: center;
    gap: .5rem;
    display: flex;

    @media ${Device.mobileS} {
        font-size: .9rem;
    }

    @media ${Device.mobileM} {
        font-size: .9rem;
    }

    @media ${Device.mobileL} {
        font-size: .9rem;
    }

    @media ${Device.tablet} {
        font-size: .9rem;
    }

    @media ${Device.laptopS} {
        font-size: .9rem;
    }

    @media ${Device.laptopM} {
        font-size: .9rem;
    }

    @media ${Device.laptopL} {
        font-size: 1rem;
    }
`;
const ProductItemLine = styled.div`
    content: ' ';
    border-bottom: 1px solid lightgray;
    flex: 1;
`;
const ProductHeader = styled.div`
    position: relative;
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    display: flex;
`;
const ProductHeaderButtons = styled.div`
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    display: flex;
`;
const ProductHeaderButton = styled.span`
    font-weight: 100;
    font-size: larger;
    user-select: none;
    cursor: pointer;
`;
const ProductHeaderText = styled.span`
    font-size: 0.7rem;
    text-transform: uppercase;
`;
const Centered = styled.div`
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    display: flex;
`;
const StepList = styled.div`
    flex-direction: column;
    gap: 1rem;
    display: flex;
    overflow: auto;
    max-height: 70vh;
`;
const StepItem = styled.div`
    flex-direction: column;
    gap: .5rem;
    display: flex;

`;
const RecipeProgressSection = styled.div`
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    display: flex;
`;
const RecipeProgressButton = styled.span`
    padding: .5rem;
    border-radius: .25rem;
    border: 1px solid ${props => props.isActive ? Colors.peach : 'lightgray'};
    background-color: ${props => props.isActive ? Colors.peach : 'white'};
    color: ${props => props.isActive ? 'white' : 'black'};
    cursor: pointer;
    user-select: none;
    text-align: center;

    &:hover {
        color: white;
        background-color: ${Colors.peach};
        border: 1px solid ${Colors.peach};
    }

    @media ${Device.mobileS} {
        padding: .25rem .5rem;
        font-size: .9rem;
    }

    @media ${Device.mobileM} {
        padding: .25rem .5rem;
        font-size: .9rem;
    }

    @media ${Device.mobileL} {
        padding: .25rem .5rem;
        font-size: .9rem;
    }

    @media ${Device.tablet} {
        padding: .25rem .5rem;
        font-size: .9rem;
    }

    @media ${Device.laptopS} {
        padding: .25rem .5rem;
        font-size: .9rem;
    }

    @media ${Device.laptopM} {
        padding: .25rem .5rem;
        font-size: .9rem;
    }

    @media ${Device.laptopL} {
        padding: .5rem;
        font-size: 1rem;
    }
`;
const EmptyPage = styled.span`
    justify-content: center;
    align-items: center;
    display: flex;
    height: 100%;
    text-align: center;
    font-family: 'Amatic SC', cursive;
    font-size: 2rem;
    color: black;

    @media ${Device.mobileS} {
        font-size: 1.5rem;
    }

    @media ${Device.mobileM} {
        font-size: 1.5rem;
    }

    @media ${Device.mobileL} {
        font-size: 1.5rem;
    }

    @media ${Device.tablet} {
        font-size: 1.5rem;
    }

    @media ${Device.laptopS} {
        font-size: 1.5rem;
    }

    @media ${Device.laptopM} {
        font-size: 1.5rem;
    }

    @media ${Device.laptopL} {
        font-size: 2rem;
    }
`;

const Columns = styled.div`
    display: flex;
    //flex: 1;
    flex-direction: column;
    gap: .75rem;
    width: 100%;
`;

const fieldIds = {
    name: 'recipeName',
    description: 'recipeDescription',
    is_public: 'recipeIsPublic',
    timebetween: 'recipeExternalUrl',
    url_name: 'recipeUrlName',
    cover_image_id: 'recipeCoverImageId',
    portion_count: 'recipePortionCount',
    image: 'recipeImage'
}
const stepFieldIds = {
    name: 'stepName',
    description: 'stepDescription',
    step_order: 'stepOrder',
    step_image: 'stepImage',
    step_time: 'stepTime',
    step_repeat_count: 'stepRepeatCount',
    step_repeat_number: 'stepRepeatNumber',
    step_exercise_id: 'stepExerciseId'
}
const otherFieldIds = {
    name: 'productName',
    product_id: 'productId',
    weight: 'productWeight'
}
function Modal(props) {
    const [recipeName, setRecipeName] = useState('');
    const [recipeDescription, setRecipeDescription] = useState('');
    const [recipeIsPublic, setRecipeIsPublic] = useState(false);
    const [recipeExternalUrl, setRecipeExternalUrl] = useState('');
    const [recipeUrlName, setRecipeUrlName] = useState('');
    const [recipePortionCount, setRecipePortionCount] = useState(1);
    const [recipeCarbs, setRecipeCarbs] = useState(0);
    const [recipeCalorie, setRecipeCalorie] = useState(0);
    const [recipeFat, setRecipeFat] = useState(0);
    const [recipeProtein, setRecipeProtein] = useState(0);
    const [recipeImage, setRecipeImage] = useState(null);
    const [recipeImagePreview, setRecipeImagePreview] = useState(null);
    const [stepName, setStepName] = useState('');
    const [stepDescription, setStepDescription] = useState('');
    const [stepImage, setStepImage] = useState(null);
    const [stepImagePreview, setStepImagePreview] = useState(null);
    const [stepTime, setStepTime] = useState(0);
    const [stepRepeatCount, setStepRepeatCount] = useState(0);
    const [stepRepeatNumber, setStepRepeatNumber] = useState(0);
    const [stepExerciseId, setStepExerciseId] = useState(null);
    const [stepOrder, setStepOrder] = useState(1);
    const [productId, setProductId] = useState('');
    const [productWeight, setProductWeight] = useState(null);
    const [products, setProducts] = useState([]);
    const storeProducts = [];//props.store.Products.products;
    const [stepIndex, setStepIndex] = useState(null);
    const [steps, setSteps] = useState([]);
    const [currentStep, setCurrentStep] = useState(0);
    const storeExercises = props.store.Exercises.exercises;

    const [finishRecipeId, setFinishRecipeId] = useState(null);
    const [finishProductStatus, setFinishProductStatus] = useState(false);
    const [finishStepsStatus, setFinishStepsStatus] = useState(false);

    const [runingFetch, setRuningFetch] = useState(false);

    function onChange(event) {
        switch(event.target.id) {
            case fieldIds.name:
                setRecipeName(event.target.value);
                break;
            case fieldIds.description:
                setRecipeDescription(event.target.value);
                break;
            case fieldIds.is_public:
                setRecipeIsPublic(event.target.value);
                break;
            case fieldIds.timebetween:
                setRecipeExternalUrl(event.target.value);
                break;
            case fieldIds.url_name:
                setRecipeUrlName(event.target.value);
                break;
            case fieldIds.portion_count:
                setRecipePortionCount(event.target.value);
                break;
            case fieldIds.image:
                setRecipeImage(event.target.files[0]);
                break;
            case stepFieldIds.name:
                setStepName(event.target.value);
                break;
            case stepFieldIds.description:
                setStepDescription(event.target.value);
                break;
            case stepFieldIds.step_image:
                setStepImage(event.target.files[0]);
                break;
            case stepFieldIds.step_time:
                setStepTime(event.target.value);
                break;
            case stepFieldIds.step_repeat_count:
                setStepRepeatCount(event.target.value);
                break;
            case stepFieldIds.step_repeat_number:
                setStepRepeatNumber(event.target.value);
                break;
            case stepFieldIds.step_exercise_id:
                setStepExerciseId(event.target.value);
                break;
            case stepFieldIds.step_order:
                setStepOrder(event.target.value);
                break;
            case otherFieldIds.product_id:
                setProductId(event.target.value);
                break;
            case otherFieldIds.weight:
                setProductWeight(event.target.value);
                break;
            default:
                break;
        }
    }

    useEffect(() => {
        // create the preview
        if (stepImage !== null) {
        const objectUrl = URL.createObjectURL(stepImage)
        setStepImagePreview(objectUrl)
     
        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl)
        }
    }, [stepImage])

    useEffect(() => {
        // create the preview
        if (recipeImage !== null) {
            const objectUrl = URL.createObjectURL(recipeImage)
            setRecipeImagePreview(objectUrl)
        
            // free memory when ever this component is unmounted
            return () => URL.revokeObjectURL(objectUrl)
        }
    }, [recipeImage])

    useEffect(() => {
        if (finishStepsStatus) {
            props.store.Workouts.loadingData();
            props.store.WorkoutItems.loadingData();
            props.store.Images.loadingData();
            props.store.Global.setModal()
        }
    }, [finishStepsStatus])

    useEffect(() => {
        let c = 0;
        let ugl = 0;
        let jir = 0;
        let protein = 0;

        products.forEach(p => {
            c += p.product.calorie/100*p.weight;
            ugl += p.product.carbs/100*p.weight;
            jir += p.product.fat/100*p.weight;
            protein += p.product.protein/100*p.weight;
        })

        setRecipeCalorie(c);
        setRecipeCarbs(ugl);
        setRecipeFat(jir);
        setRecipeProtein(protein);
    }, [products.length])

    function create() {
        setRuningFetch(true);
        const data = new FormData();

        data.append('file', recipeImage);
        data.append('time_between_exercise',recipeExternalUrl);
        data.append('name',recipeName);
        data.append('description',recipeDescription);

        props.store.Workouts.create(data)
        .then(result => {
            if (result.data.status) {
                const id = result.data.data.id;
                fetchCreateSteps(id);
            }
            else {
                setRuningFetch(false);
                toast.error('error');
                return;
            }
        }).catch(error => {
            setRuningFetch(false);
            toast.error(error)
            return;
        })
    }

    function fetchCreateProducts(data) {
        setRuningFetch(true);
        if (data === null || data === undefined) {
            setRuningFetch(false);
            toast.error('Не найден рецепт')
            return;
        }
        setFinishRecipeId(data);
        
        let productsData = [...products];
        productsData.forEach((product, index) => {
            product.recipe_id = finishRecipeId;
        })
        props.store.RecipeProducts.create({products: productsData})
        .then(resultProducts => {
            if (resultProducts.data.status) {
                fetchCreateSteps(true);
                
            }
            else {
                setRuningFetch(false);
                toast.error('error');
                return;
            }
        })
        .catch(error => {
            setRuningFetch(false);
            toast.error(error)
            return;
        })
    }

    function fetchCreateSteps(data) {
        setRuningFetch(true);
        if (data === null || data === undefined) {
            setRuningFetch(false);
            toast.error('Не добавлены продукты')
            return;
        }
        setFinishProductStatus(true);

        let stepsData = [...steps];
        let promisesList = [];
        stepsData.forEach((step, index) => {
            //step.step_order = index + 1;
            const imageData = new FormData();
            //imageData.append('file', step.image);
            imageData.append('workout_id', data);
            imageData.append('order_number', index + 1);
            imageData.append('name', step.name);
            imageData.append('description', step.description);
            imageData.append('exercise_id', step.exercise_id);
            imageData.append('repeat_count', step.repeat_count);
            imageData.append('number_in_repeat', step.number_in_repeat);
            promisesList.push(props.store.WorkoutItems.create(imageData));
        })

        Promise.all(promisesList)
        .then(r => {
            setFinishStepsStatus(true);
            setRuningFetch(false);
        })
        .catch(error => {
            setRuningFetch(false);
            toast.error(error)
            return;
        })
    }

    function addStep() {
        const step = {
            name: stepName,
            description: stepDescription,
            status: false,
            exercise_id: stepExerciseId,
            time_between_exercise: stepTime,
            repeat_count: stepRepeatCount,
            number_in_repeat: stepRepeatNumber,
            order_number: stepOrder
        }

        let currentSteps = [...steps];
        if (stepIndex !== null) {
            currentSteps[stepIndex] = step;
        }
        else {
            currentSteps.push(step);
        }
        setSteps(currentSteps);

        setStepName('');
        setStepDescription('');
        setStepImage(null);
        setStepImagePreview(null);
        setStepIndex(null);
        setStepOrder(stepOrder + 1);
        setStepExerciseId('');
        setStepTime(0);
        setStepRepeatCount(0);
        setStepRepeatNumber(0);
    }

    function editStep(data, index) {
        setStepIndex(index);
        setStepName(data.name);
        setStepDescription(data.description);
        setStepImage(data.image);
        setStepImagePreview(data.image_preview);
    }

    function deleteStep(index) {
        let currentSteps = [...steps];
        currentSteps.splice(index, 1);
        setSteps(currentSteps);
    }

    function cancelStep() {
        setStepName('');
        setStepDescription('');
        setStepImage(null);
        setStepImagePreview(null);
        setStepIndex(null);
    }

    function addProduct() {
        const findedProduct = storeProducts.find(p=> p.id === productId);
        const data = {
            name: findedProduct.name,
            product_id: productId,
            weight: productWeight,
            product: findedProduct
        }

        const currentProducts = products;
        currentProducts.push(data);
        setProducts(currentProducts);
        setProductId('');
        setProductWeight(0);
    }

    function deleteProduct(index) {
        let currentProducts = [...products];
        currentProducts.splice(index,1);
        setProducts(currentProducts);
    }

    function changePortionCount(value) {
        if (recipePortionCount + value === 0) {
            return;
        }

        setRecipePortionCount(recipePortionCount + value);
    }

    function deleteImagePreview(type) {
        if (type === 0) {
            setRecipeImage(null);
            setRecipeImagePreview(null);
        }

        if (type === 1) {
            setStepImage(null);
            setStepImagePreview(null);
        }
    }

    return <Component>
        <RecipeProgressSection>
            <RecipeProgressButton isActive={currentStep === 0} onClick={() => setCurrentStep(0)}>Информация о тренировке</RecipeProgressButton>
            <RecipeProgressButton isActive={currentStep === 1} onClick={() => setCurrentStep(1)}>Описание тренировки</RecipeProgressButton>
        </RecipeProgressSection>
        <ContentSection>
        <MainSection isVisible={currentStep === 0}>
            <Columns>
            <FileType>
                {
                    recipeImagePreview === null && <>
                        <StyledLogo />
                        <ImageAlt>Добавить обложку</ImageAlt>
                    </>
                }
                {   
                    recipeImagePreview !== null && <ImagePreview src={recipeImagePreview}/>
                }
                <FileUpload type='file' id={fieldIds.image} onChange={(event) => onChange(event)}/>
                {   
                    recipeImagePreview !== null && <DeleteButton absolute={true} onClick={() => deleteImagePreview(0)}>Удалить</DeleteButton>
                }
            </FileType>
            <Input type='text' id={fieldIds.name} value={recipeName} label='Название' onChange={(event => onChange(event))}/>
            <Textarea type='text' id={fieldIds.description} value={recipeDescription} rows='3' label='Описание' onChange={(event => onChange(event))}/>
            <Input type='number' id={fieldIds.timebetween} value={recipeExternalUrl} label='Отдых между подходами' onChange={(event => onChange(event))}/>
            {/* <Input type='text' id={fieldIds.url_name} value={recipeUrlName} label='Имя урл' onChange={(event => onChange(event))}/> */}
            </Columns>
        </MainSection>
        <AddStepSection isVisible={currentStep === 1}>
        <Title>Добавить шаг</Title>
            <Input type='text' id={stepFieldIds.name} value={stepName} label='Название шага тренировки' onChange={(event => onChange(event))}/>
            <Select id={stepFieldIds.step_exercise_id} value={stepExerciseId} onChange={(event) => onChange(event)}>
                <option key={0} value={''} hidden>Выберите упражнение</option>
            {
                storeExercises.map(p => <option key={p.id} value={p.id}>{p.name}</option>)
            }
            </Select>
            <Textarea type='text' id={stepFieldIds.description} value={stepDescription} rows='5' label='Описание' onChange={(event => onChange(event))}/>
            <Input type='number' id={stepFieldIds.step_order} value={stepOrder} label='Порядковый номер' onChange={(event => onChange(event))}/>
            <Input type='number' id={stepFieldIds.step_time} value={stepTime} label='Время между подходами' onChange={(event => onChange(event))}/>
            <Input type='number' id={stepFieldIds.step_repeat_count} value={stepRepeatCount} label='Количество подходов' onChange={(event => onChange(event))}/>
            <Input type='number' id={stepFieldIds.step_repeat_number} value={stepRepeatNumber} label='Количество повторений' onChange={(event => onChange(event))}/>
            <Centered>
                <Button onClick={() => addStep()}>{stepIndex !== null ? 'Изменить' : 'Добавить'} шаг</Button>
                {
                    stepIndex !== null && <Button onClick={() => cancelStep()}>Отмена</Button>
                }
            </Centered>
        </AddStepSection>
        <StepSection  isVisible={currentStep === 1}>
        <Title>Упражнения тренировки</Title>
            <StepList>
            {
                steps.map((step, index) => <StepItem>
                    <ProductHeader>
                        <Title>Шаг {step.order_number}. {step.name}</Title>
                        
                    </ProductHeader>
                
                    <Select disabled value={step.exercise_id}>
                        <option key={0} value={''} hidden>Выберите упражнение</option>
                    {
                        storeExercises.map(p => <option key={p.id} value={p.id}>{p.name}</option>)
                    }
                    </Select>
                    <SmallText>Время между подходами: {step.time_between_exercise}</SmallText>
                    <SmallText>Количество подходов: {step.repeat_count}</SmallText>
                    <SmallText>Количество повторений: {step.number_in_repeat}</SmallText>
                    <SmallText>{step.description}</SmallText>
                    <Centered>
                        <Button onClick={() => editStep(step, index)}>Редактировать</Button>
                        <Button onClick={() => deleteStep(index)} type='delete'>Удалить</Button>
                    </Centered>
                </StepItem>)

                
            }
            {
                steps.length === 0 && <EmptyPage>Тут пока пусто</EmptyPage>
            }
            </StepList>
        </StepSection>
        </ContentSection>
        <Centered>
                <Button onClick={() => create()}>Добавить рецепт</Button>
                
            </Centered>
        { runingFetch && <Loading><LoadingText>Обработка данных...</LoadingText></Loading> }
    </Component>
}

export default inject("store")(Modal);