import { set } from "mobx";
import { useEffect } from "react";
import { useState } from "react";
import styled from "styled-components";
import { Colors, Paths } from '../constants';
import { useNavigate } from "react-router-dom";

const Component = styled.div`
    position: relative;
    flex-direction: column;
    justify-content: flex-end;
    display: flex;
    border-radius: .25rem;
    min-width: 350px;
    width: 350px;
    height: 200px;
    padding: .5rem;
    background: ${props => props.background !== null ? 'no-repeat url("'+props.background+'")' : Colors.primary};
    background-position: center;
    background-size: cover;
    cursor: pointer;
    transition: box-shadow .3s ease-in-out;

    &:hover {
        box-shadow: inset ${Colors.secondary} 0px 0px 20px 0px;
    }

    &::before {
        content: ' ';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 60%;
        height: 50%;
        background-color: rgba(255,255,255,0.8);
        transform: translate(-50%, -50%);
    }
`;
const Title = styled.span`
    position: absolute;
    top: 50%;
    left: 50%;
    justify-content: center;
    display: flex;
    margin: auto 0;
    transform: translate(-50%, -50%);
    font-size: 1.3rem;
    font-weight: 600;    
`;
const Button = styled.button`
    transition: color .3s ease-in, background .3s ease-out;
    margin: 0 auto;
    border-radius: .25rem;
    border: 1px solid ${Colors.secondary};
    width: 150px;
    padding: .25rem;
    background: ${Colors.secondary};
    color: white;
    text-transform: uppercase;
    outline: none;
    cursor: pointer;
    font-weight: 500;

    &:hover {
        background: white;
        color: ${Colors.secondary};
    }
`;

function Workout({data}) {
    const [backgroundImage, setBackgroundImage] = useState(null);
    const [title, setTitle] = useState('');

    const navigate = useNavigate();

    function runWorkout(event) {
        event.stopPropagation();
        navigate(Paths.training+data.id)
    };

    useEffect(() => {
        if (data === null) {
            setBackgroundImage(null);
            setTitle('');
            return;
        }

        setTitle(data.name);

        if (data.image !== null && data.image !== undefined) {
            setBackgroundImage(data.image.path);
        }
        else {
            setBackgroundImage(null);
        }
    }, [data])

    return <Component background={backgroundImage} onClick={() => navigate(Paths.workout+data.id)}>
        <Title>{title}</Title>
        <Button onClick={(event) => runWorkout(event)}>Начать</Button>
    </Component>
}

export default Workout;