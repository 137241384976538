import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { declension } from '../../utils';
import { Colors } from "../../constants";
import { observer } from "mobx-react";

const Component = styled.div`
    position: relative;
    align-items: center;
    flex-direction: column;
    display: flex;
    height: 100%;
`;
const Row = styled.div`
    display: flex;
`;
const EmojiButton = styled.button`
    justify-content: center;
    display: flex;
    border: none;
    border-radius: .5rem;
    padding: .5rem;
    font-size: 2rem;
    background: none;
    outline: ${props => props.selected ? '2px solid '+Colors.primary : 'none'};
    cursor: pointer;

    &:hover {
        background-color: ${Colors.primary};
    }

    &:focus {
        outline: 2px solid ${Colors.primary};
    }
`;
const WishText = styled.span`
    font-size: smaller;
    font-style: italic;
    color: lightgrey;
`;
function Relax({time, next, isFinish}) {
    //const [selectedFeel, setFeel] = useState(undefined);
    //const feel = useRef(undefined);
    const [feel, setFeel] = useState(null); 
    const feelRef = useRef(null); // создаем объект useRef
    const [timer, setTimer] = useState(0);
    const [wish, activateWish] = useState(false);

    useEffect(() => {
        let lostTime = time;
            setTimer(lostTime);
            let intervalId = setInterval(() => {
                lostTime--;
                setTimer(lostTime);
                if (lostTime < 10) {
                    activateWish(true);
                }
            }, 1000);
    
            const timerId = setTimeout(() => {
                clearInterval(intervalId);
                next(feelRef.current);
            }, time*1000);  
    
            return () => {
                clearInterval(intervalId);
                clearTimeout(timerId);
            };
    }, [])

    useEffect(() => { // обновляем feelRef.current при изменении feel 
        feelRef.current = feel; 
    }, [feel]);

    /*
    function setFeel(value) {
        if (value === feel.current) {
            feel.current = undefined;
            return;
        }

        if (isFinish) {
            next(value);
        }
        else {
            feel.current = value;
        }
    }
    */

    return <Component>
        <h1 style={{fontSize: '4rem'}}>{isFinish ? 'Тренировка завершена!🥳' : 'Отдых'}</h1>
        <p style={{fontSize: '2rem', fontStyle: 'italic'}}>{timer} {declension(timer, ['секунда', 'секунды', 'cекунд'])}</p>
        <p>Как себя чувствуешь?</p>
        <Row>
            <EmojiButton onClick={() => setFeel(-1)} selected={feel === -1}>🫠</EmojiButton>
            <EmojiButton onClick={() => setFeel(0)} selected={feel === 0}>🙂</EmojiButton>
            <EmojiButton onClick={() => setFeel(1)} selected={feel === 1}>😏</EmojiButton>
        </Row>
        { feel === null && wish && <WishText>Выбери, пожалуйста, что-нибудь🙄</WishText> }
    </Component>
}

export default Relax