import { observer } from "mobx-react";
import { dataStore } from '../../store/data';
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import styled from "styled-components";
import Image from "../../components/image_preview";
import { Colors, Config } from "../../constants";
import { v4 } from 'uuid';
import { toast } from "react-toastify";
import Imag from '../../store/images';

const Page = styled.div`
    align-items: center;
flex-direction: column;
    gap: 1rem;
    display: flex;
    width: 800px;
    margin: 1rem auto;
    overflow: auto;
    background-color: white;
    padding: 1rem;
    border-radius: .25rem;
`;
const PhotoList = styled.div`
    padding: 1rem;
    flex-direction: row;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
`;
const InputContainer = styled.button`
    position: relative;
    //margin: 0 auto;
    border: 1px solid ${Colors.primary};
    border-radius: .25rem;
    width: fit-content;
    padding: .5rem;
    background: ${Colors.primary};
    color: white;
    cursor: pointer;
`;
const ImageInput = styled.input`
    width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
`;
function Diary(props) {
    const { id } = useParams();
    const diary = dataStore.diaries.find(dd => dd.id === id);
    console.log(diary);
    const [photos, setPhotos] = useState([]);
    if (diary === undefined) {
        return <span>Loading</span>
    }
    
    function addPhoto(photo) {
        let p = [...photos];
        p.push(photo);
        setPhotos(p);
    }

    function removePhoto(index) {
        let p = [...photos];
        p.splice(index, 1);
        setPhotos(p);
    }

    async function upload() {
        if (photos.length > 0) {
            try {
                let coverId = null;
                let data = new FormData();
                for (let photo of photos) {
                    data = new FormData();
                    coverId = v4();
                    data.append('file',photo);
                    data.append('id', coverId);
                    const response = await Imag.upload(data);
                    if (!response.data.status) {
                        toast.error(response.data.message);
                        return;
                    }

                    data = new FormData();
                    data.append('diaryId', id);
                    data.append('imageId', coverId);
                    data.append('photoComment', '');

                    const stepResponse = await dataStore.create(Config.DIARYPHOTOS, data);
                    if (!stepResponse.data.status) {
                        toast.error(stepResponse.data.message);
                        return;
                    }
                }
            }
            catch (error) {
                toast.error(error?.response?.data?.message || error.message);
                return;
            };
        }

        setPhotos([])
        await dataStore.fetch();
    }

    return <Page>
        <h1>{diary.workout.name}</h1>
        {
            (photos.length + diary.photos.length) < 5 && <>
            <InputContainer>
                <ImageInput type='file' id='cover' onChange={(event) => addPhoto(event.target.files[0])}/>
                <label for='cover'>Добавить фотографии в дневник</label>
            </InputContainer>
            <h2>Будут загружены</h2>
            <PhotoList>
            {
                photos.length > 0 && photos.map((photo, index) => <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                    <Image data={photo} width='200px' height='200px'/>    
                    <span onClick={() => removePhoto(index)} style={{color: 'red', fontSize: 'smaller', cursor: 'pointer'}}>Удалить</span>
                    </div>
                )
            }
            </PhotoList>
            <button onClick={() => upload()}>Загрузить</button>
            </>
        }
        
        <div style={{display: 'flex', flexDirection: 'column'}}>
        {
            diary.photos.map(photo => <Image width='100%' height='auto' data={photo.image.path}/>)
        }
        </div>
        
    </Page>
}

export default observer(Diary);