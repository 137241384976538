import { makeObservable, observable, action, computed } from "mobx";
import axios from 'axios';
import { getToken } from '../utils';
import { Config } from '../constants';
import { toast } from 'react-toastify';

const NODE_ENV = process.env.NODE_ENV || 'production';
class Exercises {
    exercises = []


    constructor(title) { 
        makeObservable(this, {
            exercises: observable,
            loadingData: action,
            create: action,
            delete: action,
            setExercises: action,
            get: action
        })

        this.loadingData = this.loadingData.bind(this);
        this.create = this.create.bind(this);
        this.delete = this.delete.bind(this);
        this.setExercises = this.setExercises.bind(this);

        axios.defaults.headers.common['x-user-language'] = 'ru';
        axios.defaults.headers.common['x-app-name'] = 'fitness';
        axios.defaults.headers.common['x-access-token'] = getToken();
        //this.loadingData();
    }

    async loadingData() {
        

        let exercisesResponse, stepsResponse;
        try {
            exercisesResponse = await axios.get(Config.api.host.fitness[NODE_ENV]+Config.api.exercises.get_all);
            stepsResponse = await axios.get(Config.api.host.fitness[NODE_ENV]+Config.api.exercise_items.get_all);
        }
        catch (error) {
            toast.error(error?.response?.data?.message || error.message);
            return;
        }

        if (!exercisesResponse.data.status || !stepsResponse.data.status) {
            toast.error(exercisesResponse.data.message);
            return;
        }

        let exercises = [];
        let steps;
        exercisesResponse.data.data.forEach(exercise => {
            steps = [];
            if (exercise.image_id === null) {
                console.log(exercise.id, '!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!')
            }
            stepsResponse.data.data
            .filter(step => step.exercise_id === exercise.id)
            .forEach(step => {
                steps.push(step);
            })

            exercise.steps = steps;
            exercises.push(exercise);
        });

        this.setExercises(exercises);
    }

    setExercises(data) {
        this.exercises = data;
    }

    get(id) {
        return this.exercises.find(ex => ex.id === id);
    }

    create(data) {
        return axios.post(Config.api.host.fitness[NODE_ENV]+Config.api.exercises.create, data);
    }

    update(data) {
        return axios.put(Config.api.host.fitness[NODE_ENV]+Config.api.exercises.update, data);
    }

    delete(id) {
        return axios.delete(Config.api.host.fitness[NODE_ENV]+Config.api.exercises.delete+id);
    }
}

export default new Exercises();