import { useState, useEffect } from "react";
import styled from "styled-components";
import { declension } from "../utils";
const Component = styled.div`
    flex-direction: row;
    gap: 1rem;
    align-items: center;
    display: flex;
    border: 1px solid lightgrey;
    border-radius: .25rem;
    padding: .5rem;
    background-color: white;
`;

export default function HistoryWorkout({data}) {
    const dateFormat = new Intl.DateTimeFormat('ru-ru');
    const [feel, setFeel] = useState('');
    const [time, setTime] = useState(0);
    useEffect(() => {
        let num = (new Date(data.updatedAt)-new Date(data.createdAt))/1000/60;
        setTime(num.toFixed(0));

        switch (data.feel) {
            case -1:
                setFeel('🫠')
                break;
            case 0:
                setFeel('🙂');
                break;
            case 1:
                setFeel('😏')
            default:
                setFeel('🤷')
                break;
        }
    }, [data])
    return <Component>
        <span>{feel}</span>
        <span>{dateFormat.format(new Date(data.createdAt))}</span>
        <span>{data.workout.name}</span>
        {/* <span>Продолжительность: {time} { declension(time,  ['минута', 'минуты', 'минут'])}</span> */}
    </Component>
};