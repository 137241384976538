import { makeObservable, observable, action, computed } from "mobx";
import axios from 'axios';
import { getToken } from '../utils';
import { Config } from '../constants';
import { toast } from 'react-toastify';

const NODE_ENV = process.env.NODE_ENV || 'production';
class Weights {
    weights = []

    constructor(title) { 
        makeObservable(this, {
            weights: observable,
            loadingData: action,
            create: action,
            delete: action,
            setWeights: action,
            get: action
        })

        this.loadingData = this.loadingData.bind(this);
        this.create = this.create.bind(this);
        this.delete = this.delete.bind(this);
        this.setWeights = this.setWeights.bind(this);

        axios.defaults.headers.common['x-user-language'] = 'ru';
        axios.defaults.headers.common['x-app-name'] = 'fitness';
        axios.defaults.headers.common['x-access-token'] = getToken();
        this.loadingData();
    }

    async loadingData() {
        let weightsResponse, itemsResponse, photosResponse;
        try {
            weightsResponse = await axios.get(Config.api.host.fitness[NODE_ENV]+Config.api.weights.get_all);
        }
        catch (error) {
            toast.error(error?.response?.data?.message || error.message);
            return;
        }

        if (!weightsResponse.data.status) {
            toast.error(weightsResponse.data.message);
            return;
        }

        this.setWeights(weightsResponse.data.data);
    }

    setWeights(data) {
        this.weights = data;
    }

    get(id) {
        return this.weights.find(ex => ex.id === id);
    }

    create(data) {
        return axios.post(Config.api.host.fitness[NODE_ENV]+Config.api.weights.create, data);
    }

    update(data) {
        return axios.put(Config.api.host.fitness[NODE_ENV]+Config.api.weights.update, data);
    }

    delete(id) {
        return axios.delete(Config.api.host.fitness[NODE_ENV]+Config.api.weights.delete+id);
    }
}

export default new Weights();