import styled from "styled-components";
import { Colors } from "../../constants";
import { useEffect, useState } from "react";
import { declension } from "../../utils";

const Component = styled.div`
    position: relative;
    flex-direction: column;
    justify-content: end;
    align-items: center;
    display: flex;
    border-radius: .25rem;
    min-width: 300px;
    width: 300px;
    height: 300px;
    //padding: .5rem;
    background: ${props => props.background !== null ? 'no-repeat url("'+props.background+'")' : Colors.primary};
    background-position: center;
    background-size: cover;
    cursor: pointer;
    color: black;

    &:hover {
        box-shadow: ${props => props.count > 0 ? Colors.primary+' 0px 0px 20px 0px' : 'none'};
    }
`;
const Title = styled.span`
    z-index: 1;
    font-weight: 600;
    font-size: 1.5rem;
    padding: .25rem;
    width: 100%;
    text-align: center;
    background-color: rgba(255,255,255,0.85);

`;
const Text = styled.span`
    z-index: 1;
    font-size: 1rem;
    text-align: center;
    width: 100%;
    background-color: rgba(255,255,255,0.85);
`;

export default function Diary({data, onClick}) {
    const dateFormat = new Intl.DateTimeFormat('ru-ru');
    const [time, setTime] = useState(0);
    const [imgSrc, setImgSrc] = useState(null);
    const [feel, setFeel] = useState('');
    useEffect(() => {
        let num = (new Date(data.updatedAt)-new Date(data.createdAt))/1000/60;
        setTime(num.toFixed(0));

        if (data.photos.length > 0) {
            setImgSrc(data.photos[0].image?.path);
        }
        else if (data.workout !== null && data.workout.image !== null) {
            setImgSrc(data.workout.image?.path);
        }

        if (data.feel !== null) {
            switch (data.feel) {
                case -1:
                    setFeel('🫠')
                    break;
                case 0:
                    setFeel('🙂');
                    break;
                case 1:
                    setFeel('😏')
                default:
                    break;
            }
        }
    }, [data])
    const [photos, setPhotos] = useState([]);
    
    return <Component background={imgSrc} onClick={onClick} count={data.photos.length}>
        <Title>{data.workout.name}</Title>
        <Text>{dateFormat.format(new Date(data.createdAt))}</Text>
        {/* <Text>{time} { declension(time, ['минута', 'минуты', 'минут'])}</Text> */}
        <Text>{feel}</Text> 
    </Component>
}