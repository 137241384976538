import { observe, toJS } from "mobx";
import { inject } from "mobx-react";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import Image from "../components/image_preview";
import { Modals } from "../constants";
import { toast } from "react-toastify";

const Component = styled.div`
    flex-direction: column;
    gap: 1rem;
    display: flex;
    width: 800px;
    margin: 1rem auto;
    overflow: auto;
    background-color: white;
    padding: 1rem;
    border-radius: .25rem;
`;
const EmojiButton = styled.span`
    cursor: pointer;
`;

function Page(props) { 
    const { id } = useParams();
    const [exercise, setExercise] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const ex = toJS(props.store.Exercises.get(id));
        if (ex) {
            ex.image = ex.image_id ? toJS(props.store.Images.mapImages[ex.image_id]) : null;
            
            ex.steps.forEach(e => {
                e.image = e.image_id ? toJS(props.store.Images.mapImages[e.image_id]) : null;
            })

            setExercise(ex);
        }
    }, [id])

    async function remove() {
        props.store.Global.newDialog({
            title: 'Удаление',
            message: 'Удалить упражнение?',
            positiveClick: () => {
                deleteExercise();                
            },
            negativeClick: () => {
                props.store.Global.hideDialog();
            }
        })
    } 

    async function deleteExercise() {
        try {
            const response = await props.store.Exercises.delete(id);
            if (!response.data.status) {
                toast.error(response.data.message);
                return;
            }
        }
        catch (error) {
            toast.error(error?.response?.data?.message || error.message);
            return;
        }
        props.store.Exercises.loadingData();
        props.store.Global.hideDialog();
        navigate('/exercises')
    }

    return <Component>
        {
            exercise === null && <span>Loading</span>
        }
        {
            exercise !== null && <>
                <h1>{exercise.name}<EmojiButton onClick={() => navigate('/edit-exercise/'+exercise.id)}>✏️</EmojiButton>
                                    <EmojiButton onClick={() => remove()}>❌</EmojiButton></h1>
                { exercise.image && <Image centered width='100%' height='auto' data={exercise.image.path}/> }
                <p>{exercise.description}</p>
                {
                    exercise.steps && <h2>Шаги</h2>
                }
                {
                    exercise.steps && exercise.steps.map((step, index) => <div>
                        <h3>Шаг #{index+1}</h3>
                        {step.image && <Image centered width='100%' height='auto'  data={step.image.path}/>}
                        <span>{step.description}</span>
                    </div>)
                }
            </>
        }
    </Component>
}

export default inject('store')(Page);