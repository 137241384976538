import styled from 'styled-components';
import { observer } from "mobx-react";
import { useLocation } from 'react-router-dom';
import Menu from './menu';
import Avatar from './avatar';
import { ReactComponent as LogoFile } from "../icons/vectorpaint.svg";
import { Device } from '../constants';
import * as vars from '../constants';
import { useEffect, useState } from 'react';
const Component = styled.div`
    height: 100px;
    width: 100%;
    box-shadow: rgb(0 0 0 / 57%) 0px 3px 10px -8px;
    background: linear-gradient(147deg, ${vars.Colors.primary}, ${vars.Colors.secondary});
    //background-color: blue;

    @media ${Device.mobileS} {
        height: 50px;
    }

    @media ${Device.mobileM} {
        height: 50px;
    }

    @media ${Device.mobileL} {
        height: 50px;
    }

    @media ${Device.tablet} {
        height: 50px;
    }

    @media ${Device.laptopS} {
        height: 50px;
    }

    @media ${Device.laptopM} {
        height: 50px;
    }

    @media ${Device.laptopL} {
        height: 50px;
    }
`;
const HeaderSection = styled.div`
    flex-direction: row;
    align-items: center;
    gap: 2rem;
    display: flex;
    padding: 0 2rem;
    height: 50px;
    width: 100%;
    //background-color: white;

    @media ${Device.mobileS} {
        gap: 0.5rem;
        padding: 0 1rem;
        height: 50px;
    }

    @media ${Device.mobileM} {
        gap: 0.5rem;
        padding: 0 1rem;
        height: 50px;
    }

    @media ${Device.mobileL} {
        gap: 0.5rem;
        padding: 0 2rem;
        height: 50px;
    }

    @media ${Device.tablet} {
        gap: 0.5rem;
        padding: 0 2rem;
        height: 50px;
    }

    @media ${Device.laptopS} {
        gap: 0.5rem;
        padding: 0 2rem;
        height: 50px;
    }

    @media ${Device.laptopM} {
        gap: 0.5rem;
        padding: 0 2rem;
        height: 50px;
    }

    @media ${Device.laptopL} {
        gap: 2rem;
        padding: 0 2rem;
        height: 50px;
    }
`;
const Logo = styled(LogoFile)`

    @media ${Device.mobileS} {
        width: 100px;
    }

    @media ${Device.mobileM} {
        width: 125px;
    }

    @media ${Device.mobileL} {
        width: 125px;
    }

    @media ${Device.tablet} {
        width: 150px;
    }

    @media ${Device.laptopS} {
        width: 150px;
    }

    @media ${Device.laptopM} {
        width: 150px;
    }

    @media ${Device.laptopL} {
        width: 150px;
    }
`;
const Title = styled.span`
    flex: 1;
    text-align: center;
    color: white;
    font-size: 1.3rem;
`;
function Header(props) {
    const { pathname } = useLocation();
    const [headerTitle, setHeaderTitle] = useState('');
    useEffect(() => {
        if (pathname === vars.Paths.createExercise) {
            setHeaderTitle('Создание упражнения');
            return;
        }    

        if (pathname === vars.Paths.edit_exercise) {
            setHeaderTitle('Редактирование упражнения');
            return;
        }
    
        setHeaderTitle('');
    }, [pathname])
    return <Component>
        <HeaderSection>
            <img src='/logo.png' />
            <Title>{headerTitle}</Title>
            <Menu />
            <Avatar {...props}/>
        </HeaderSection>
    </Component>
}

export default observer(Header);