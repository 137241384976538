import { inject } from "mobx-react";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import Image from "../components/image_preview";
import { toast } from "react-toastify";

const Page = styled.div`
flex-direction: column;
    gap: 1rem;
    display: flex;
    width: 800px;
    margin: 1rem auto;
    overflow: auto;
    background-color: white;
    padding: 1rem;
    border-radius: .25rem;
`;
const EmojiButton = styled.span`
    cursor: pointer;
`;
function WorkoutPage(props) {
    const { id } = useParams();
    const [workout, setWorkout] = useState(null);
    const navigate = useNavigate();
    useEffect(() => {
        const w = props.store.Workouts.workouts.find(ww => ww.id === id);
        if (w) {
            w.image = w.image_id ? props.store.Images.mapImages[w.image_id] : null;

            w.steps.sort(function (a,b) {return a.order_number - b.order_number});
            w.steps.forEach(step => {
                if (step.exercise_id) {
                    step.exercise = props.store.Exercises.exercises.find(e => e.id === step.exercise_id);    
                    if (step.exercise !== undefined) {
                    step.exercise.image = step.exercise?.image_id ? props.store.Images.mapImages[step.exercise.image_id] : null;
                    }
                }
                else {
                    step.exercise = null;
                }
            });
            setWorkout(w);
            console.log(w);
        }
    }, [id])

    async function remove() {
        props.store.Global.newDialog({
            title: 'Удаление',
            message: 'Удалить тренировку?',
            positiveClick: () => {
                deleteExercise();                
            },
            negativeClick: () => {
                props.store.Global.hideDialog();
            }
        })
    } 

    async function deleteExercise() {
        try {
            const response = await props.store.Workouts.delete(id);
            if (!response.data.status) {
                toast.error(response.data.message);
                return;
            }
        }
        catch (error) {
            toast.error(error?.response?.data?.message || error.message);
            return;
        }
        await props.store.Workouts.loadingData();
        await props.store.Global.hideDialog();
        navigate('/workouts')
    }

    return <Page>
        {
            workout === null && <span>Loading</span>
        }
        {
            workout !== null && <>
                <h1>{workout.name}<EmojiButton onClick={() => navigate('/edit-workout/'+workout.id)}>✏️</EmojiButton>
                                    <EmojiButton onClick={() => remove()}>❌</EmojiButton></h1>
                { workout.image && <Image centered width='100%' height='auto' data={workout.image.path}/> }
                <span>Время между упражнениями: {workout.time_between_exercise} секунд</span>
                <p>{workout.description}</p>
                {
                    workout.steps && <h2>Шаги</h2>
                }
                {
                    workout.steps && workout.steps.map((step, index) => <div>
                        <h3>#{step.order_number} {step.exercise?.name || step.name}</h3>
                        { step?.exercise?.image && <Image centered width='100%' height='auto' data={step.exercise.image.path}/> }
                        <span>{step.description}</span>
                        <span>{step.repeat_count}x{step.number_in_repeat} с отдыхом {step.time_between_exercise} секунд</span>
                    </div>)
                }
            </>
        }
    </Page>
}

export default inject('store')(WorkoutPage);