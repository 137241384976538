import { useEffect, useState } from 'react';
import styled from 'styled-components';
import Input from '../input_material';
import Textarea from '../textarea_material';
import Button from '../button';
import axios from 'axios';
import { Colors, Config, Device } from '../../constants';
import { toast } from 'react-toastify';
import { inject } from 'mobx-react';
import { ReactComponent as ReactLogo } from "../../icons/food.svg";
import { ReactComponent as DeleteIcon } from "../../icons/delete.svg";
import Select from '../select';

const Component = styled.div`
    position: relative;
    flex-direction: column;
    gap: 1rem;
    display: flex;
    max-height: 90vh;
    padding: 1rem;
    overflow-y: auto;


    @media ${Device.mobileS} {
        gap: .75rem;
    }

    @media ${Device.mobileM} {
        gap: .75rem;
    }

    @media ${Device.mobileL} {
        gap: .75rem;
    }

    @media ${Device.tablet} {
        gap: .75rem;
    }

    @media ${Device.laptopS} {
        gap: .75rem;
    }

    @media ${Device.laptopM} {
        gap: .75rem;
        max-width: 70vw;
    }

    @media ${Device.laptopL} {
        gap: 1rem;
        max-width: 50vw;
    }
`;
const Loading = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.2);
`;
const LoadingText = styled.span`
    border-radius: .25rem;
    padding: 1rem;
    background-color: white;
    user-select: none;
`;

const ContentSection = styled.div`
    flex-direction: row;
    display: flex;
    gap: 2rem;
    width: 100%;

    @media ${Device.mobileS} {
        flex-direction: column;
    }

    @media ${Device.mobileM} {
        flex-direction: column;
    }

    @media ${Device.mobileL} {
        flex-direction: column;
    }

    @media ${Device.tablet} {
        flex-direction: row;
    }

    @media ${Device.laptopS} {
        flex-direction: row;
    }

    @media ${Device.laptopM} {
        flex-direction: row;
    }

    @media ${Device.laptopL} {
        flex-direction: row;
    }
`;
const MainSection = styled.div`
    flex-direction: column;
    gap: 1rem;
    display: ${props => props.isVisible ? 'flex' : 'none'};
    width: 40vw;
    /* padding: 1rem; */

    @media ${Device.mobileS} {
        flex-direction: column;
        gap: .75rem;
        width: 80vw;
    }


    @media ${Device.mobileM} {
        flex-direction: column;
        gap: .75rem;
        width: 80vw;
    }

    @media ${Device.mobileL} {
        flex-direction: column;
        gap: .75rem;
        width: 80vw;
    }

    @media ${Device.tablet} {
        flex-direction: row;
        gap: .75rem;
        width: 90vw;
    }

    @media ${Device.laptopS} {
        flex-direction: row;
        gap: .75rem;
        width: 70vw;
    }

    @media ${Device.laptopM} {
        flex-direction: row;
        gap: .75rem;
        width: 70vw;
    }

    @media ${Device.laptopL} {
        flex-direction: row;
        gap: 1rem;
        width: 40vw;
    }
`;
const AddStepSection = styled.div`
    flex-direction: column;
    gap: 0.75rem;
    display: ${props => props.isVisible ? 'flex' : 'none'};
    width: 400px;

    @media ${Device.mobileS} {
        width: 80vw;
    }

    @media ${Device.mobileM} {
        width: 80vw;
    }

    @media ${Device.mobileL} {
        width: 80vw;
    }

    @media ${Device.tablet} {
        width: 300px;
    }

    @media ${Device.laptopS} {
        width: 300px;
    }

    @media ${Device.laptopM} {
        width: 400px;
    }

    @media ${Device.laptopL} {
        width: 400px;
    }
`;
const StepSection = styled.div`
    flex-direction: column;
    gap: 0.75rem;
    display: ${props => props.isVisible ? 'flex' : 'none'};
    width: 30vw;

    @media ${Device.mobileS} {
        width: 100%;
    }

    @media ${Device.mobileM} {
        width: 100%;
    }

    @media ${Device.mobileL} {
        width: 100%;
    }

    @media ${Device.tablet} {
        width: 300px;
    }

    @media ${Device.laptopS} {
        width: 300px;
    }

    @media ${Device.laptopM} {
        width: 400px;
    }

    @media ${Device.laptopL} {
        width: 400px;
    }
`;
const FileUpload = styled.input`
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    width: 400px;
    height: 200px;
    cursor: pointer;

    @media ${Device.mobileS} {
        width: 100%;
        height: 200px;
    }

    @media ${Device.mobileM} {
        width: 100%;
        height: 200px;
    }

    @media ${Device.mobileL} {
        width: 100%;
        height: 200px;
    }

    @media ${Device.tablet} {
        width: 300px;
        height: 150px;
    }

    @media ${Device.laptopS} {
        width: 350px;
        height: 150px;
    }

    @media ${Device.laptopM} {
        width: 400px;
        height: 200px;
    }

    @media ${Device.laptopL} {
        width: 400px;
        height: 200px;
    }
`;
const FileType = styled.div`
    position: relative;
    margin: 0 auto;
    cursor: pointer;
    border: 1px solid lightgray;
    border-radius: .25rem;
    width: 400px;
    height: 200px;

    @media ${Device.mobileS} {
        width: 100%;
        height: 200px;
    }

    @media ${Device.mobileM} {
        width: 100%;
        height: 200px;
    }

    @media ${Device.mobileL} {
        width: 100%;
        height: 200px;
    }

    @media ${Device.tablet} {
        width: 300px;
        height: 150px;
    }

    @media ${Device.laptopS} {
        width: 300px;
        height: 150px;
    }

    @media ${Device.laptopM} {
        width: 400px;
        height: 200px;
    }

    @media ${Device.laptopL} {
        width: 400px;
        height: 200px;
    }
`;
const DeleteButton = styled(DeleteIcon)`
    position: ${props => props.absolute ? 'absolute' : 'relative'};
    right: ${props => props.absolute ? '.5rem' : '0'};
    top: ${props => props.absolute ? '.5rem' : '0'};
    width: 24px;
    height: 24px;
    fill: ${Colors.red};
`;
const StyledLogo = styled(ReactLogo)`
    position: absolute;
    top: 0;
    left: 0;
    height: 150px;
    width: 400px;

    @media ${Device.mobileS} {
        width: 100%;
        height: 150px;
    }

    @media ${Device.mobileM} {
        width: 100%;
        height: 150px;
    }

    @media ${Device.mobileL} {
        width: 100%;
        height: 150px;
    }

    @media ${Device.tablet} {
        width: 300px;
        height: 100px;
    }

    @media ${Device.laptopS} {
        width: 300px;
        height: 100px;
    }

    @media ${Device.laptopM} {
        width: 400px;
        height: 150px;
    }

    @media ${Device.laptopL} {
        width: 400px;
        height: 150px;
    }
`;
const ImageAlt = styled.span`
    position: absolute;
    bottom: 0.5rem;
    left: 0;
    font-family: 'Amatic SC', cursive;
    width: 400px;
    text-align: center;
    color: ${Colors.peach};
    font-size: 1.5rem;

    @media ${Device.mobileS} {
        width: 100%;
    }

    @media ${Device.mobileM} {
        width: 100%;
    }

    @media ${Device.mobileL} {
        width: 100%;
    }

    @media ${Device.tablet} {
        width: 300px;
    }

    @media ${Device.laptopS} {
        width: 300px;
    }

    @media ${Device.laptopM} {
        width: 400px;
    }

    @media ${Device.laptopL} {
        width: 400px;
    }
`;
const ImagePreview = styled.img`
    border-radius: .25rem;
    width: 400px;
    height: 200px;
    margin: ${props => props.centered !== undefined ? '0 auto' : '0'};
    object-fit: cover;

    @media ${Device.mobileS} {
        width: 100%;
        height: 200px;
    }

    @media ${Device.mobileM} {
        width: 100%;
        height: 200px;
    }

    @media ${Device.mobileL} {
        width: 100%;
        height: 200px;
    }

    @media ${Device.tablet} {
        width: 300px;
        height: 150px;
    }

    @media ${Device.laptopS} {
        width: 300px;
        height: 150px;
    }

    @media ${Device.laptopM} {
        width: 400px;
        height: 200px;
    }

    @media ${Device.laptopL} {
        width: 400px;
        height: 200px;
    }
`;
// const DeleteButton = styled.button`
//     position: absolute;
//     top: 0.5rem;
//     right: 0.5rem;
//     border: 1px solid white;
//     border-radius: .25rem;
//     padding: 0.25rem;
//     outline: none;
//     color: lightgray;
//     background-color: white;
//     font-size: smaller;
//     cursor: pointer;
//     &:hover {
//         color: ${Colors.red}
//     }
// `;
const NutrientsList = styled.div`
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: space-between;
    display: flex;

    @media ${Device.mobileS} {
        gap: .5rem;
    }

    @media ${Device.mobileM} {
        gap: .5rem;
    }

    @media ${Device.mobileL} {
        gap: .5rem;
    }

    @media ${Device.tablet} {
        gap: .5rem;
    }

    @media ${Device.laptopS} {
        gap: .5rem;
    }

    @media ${Device.laptopM} {
        gap: 1rem;
    }

    @media ${Device.laptopL} {
        gap: 1rem;
    }
`;
const NutrientItem = styled.div`
    flex-direction: column;
    justify-content: center;
    display: flex;
    flex: 1;
    border-radius: .25rem;
    border: 1px solid lightgray;
    padding: .5rem;
    min-width: 40%;
    max-width: 50%;
    text-align: center;
`;
const NutrientTitle = styled.span`
    font-size: 0.9rem;

    @media ${Device.mobileS} {
        font-size: 0.7rem;
    }

    @media ${Device.mobileM} {
        font-size: 0.7rem;
    }

    @media ${Device.mobileL} {
        font-size: 0.8rem;
    }

    @media ${Device.tablet} {
        font-size: 0.8rem;
    }

    @media ${Device.laptopS} {
        font-size: 0.8rem;
    }

    @media ${Device.laptopM} {
        font-size: 0.8rem;
    }

    @media ${Device.laptopL} {
        font-size: 0.9rem;
    }
`;
const NutrientValue = styled.span`
    font-size: 1.3rem;
    color: ${props => props.color};

    @media ${Device.mobileS} {
        font-size: .9rem;
    }

    @media ${Device.mobileM} {
        font-size: .9rem;
    }

    @media ${Device.mobileL} {
        font-size: 1rem;
    }

    @media ${Device.tablet} {
        font-size: 1rem;
    }

    @media ${Device.laptopS} {
        font-size: 1rem;
    }

    @media ${Device.laptopM} {
        font-size: 1rem;
    }

    @media ${Device.laptopL} {
        font-size: 1.2rem;
    }
`;
const SmallText = styled.span`
    font-size: smaller;
`;
const Title = styled.span`
    font-size: 1.6rem;
    font-family: 'Amatic SC', cursive;

    @media ${Device.mobileS} {
        font-size: 1.5rem;
    }


    @media ${Device.mobileM} {
        font-size: 1.5rem;
    }

    @media ${Device.mobileL} {
        font-size: 1.5rem;
    }

    @media ${Device.tablet} {
        font-size: 1.5rem;
    }

    @media ${Device.laptopS} {
        font-size: 1.5rem;
    }

    @media ${Device.laptopM} {
        font-size: 1.5rem;
    }

    @media ${Device.laptopL} {
        font-size: 1.6rem;
    }
`;
const ProductList = styled.div`
    flex-direction: column;
    gap: .25rem;
    display: flex;
`;
const ProductItem = styled.div`
    position: relative;
    flex-direction: row;
    align-items: center;
    gap: .5rem;
    display: flex;

    @media ${Device.mobileS} {
        font-size: .9rem;
    }

    @media ${Device.mobileM} {
        font-size: .9rem;
    }

    @media ${Device.mobileL} {
        font-size: .9rem;
    }

    @media ${Device.tablet} {
        font-size: .9rem;
    }

    @media ${Device.laptopS} {
        font-size: .9rem;
    }

    @media ${Device.laptopM} {
        font-size: .9rem;
    }

    @media ${Device.laptopL} {
        font-size: 1rem;
    }
`;
const ProductItemLine = styled.div`
    content: ' ';
    border-bottom: 1px solid lightgray;
    flex: 1;
`;
const ProductHeader = styled.div`
    position: relative;
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    display: flex;
`;
const ProductHeaderButtons = styled.div`
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    display: flex;
`;
const ProductHeaderButton = styled.span`
    font-weight: 100;
    font-size: larger;
    user-select: none;
    cursor: pointer;
`;
const ProductHeaderText = styled.span`
    font-size: 0.7rem;
    text-transform: uppercase;
`;
const Centered = styled.div`
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    display: flex;
`;
const StepList = styled.div`
    flex-direction: column;
    gap: 1rem;
    display: flex;
    overflow: auto;
    max-height: 70vh;
`;
const StepItem = styled.div`
    flex-direction: column;
    gap: .5rem;
    display: flex;

`;
const RecipeProgressSection = styled.div`
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    display: flex;
`;
const RecipeProgressButton = styled.span`
    padding: .5rem;
    border-radius: .25rem;
    border: 1px solid ${props => props.isActive ? Colors.peach : 'lightgray'};
    background-color: ${props => props.isActive ? Colors.peach : 'white'};
    color: ${props => props.isActive ? 'white' : 'black'};
    cursor: pointer;
    user-select: none;
    text-align: center;

    &:hover {
        color: white;
        background-color: ${Colors.peach};
        border: 1px solid ${Colors.peach};
    }

    @media ${Device.mobileS} {
        padding: .25rem .5rem;
        font-size: .9rem;
    }

    @media ${Device.mobileM} {
        padding: .25rem .5rem;
        font-size: .9rem;
    }

    @media ${Device.mobileL} {
        padding: .25rem .5rem;
        font-size: .9rem;
    }

    @media ${Device.tablet} {
        padding: .25rem .5rem;
        font-size: .9rem;
    }

    @media ${Device.laptopS} {
        padding: .25rem .5rem;
        font-size: .9rem;
    }

    @media ${Device.laptopM} {
        padding: .25rem .5rem;
        font-size: .9rem;
    }

    @media ${Device.laptopL} {
        padding: .5rem;
        font-size: 1rem;
    }
`;
const EmptyPage = styled.span`
    justify-content: center;
    align-items: center;
    display: flex;
    height: 100%;
    text-align: center;
    font-family: 'Amatic SC', cursive;
    font-size: 2rem;
    color: black;

    @media ${Device.mobileS} {
        font-size: 1.5rem;
    }

    @media ${Device.mobileM} {
        font-size: 1.5rem;
    }

    @media ${Device.mobileL} {
        font-size: 1.5rem;
    }

    @media ${Device.tablet} {
        font-size: 1.5rem;
    }

    @media ${Device.laptopS} {
        font-size: 1.5rem;
    }

    @media ${Device.laptopM} {
        font-size: 1.5rem;
    }

    @media ${Device.laptopL} {
        font-size: 2rem;
    }
`;

const fieldIds = {
    name: 'recipeName',
    description: 'recipeDescription',
    is_public: 'recipeIsPublic',
    external_url: 'recipeExternalUrl',
    url_name: 'recipeUrlName',
    cover_image_id: 'recipeCoverImageId',
    portion_count: 'recipePortionCount',
    image: 'recipeImage'
}
const stepFieldIds = {
    name: 'stepName',
    description: 'stepDescription',
    step_order: 'stepOrder',
    step_image: 'stepImage'
}
const otherFieldIds = {
    name: 'productName',
    product_id: 'productId',
    weight: 'productWeight'
}

const Columns = styled.div`
    display: flex;
    //flex: 1;
    flex-direction: column;
    gap: .75rem;
`;
function Modal(props) {
    const [recipeId, setRecipeId] = useState('');
    const [recipeName, setRecipeName] = useState('');
    const [recipeDescription, setRecipeDescription] = useState('');
    const [recipeIsPublic, setRecipeIsPublic] = useState(false);
    const [recipeExternalUrl, setRecipeExternalUrl] = useState('');
    const [recipeUrlName, setRecipeUrlName] = useState('');
    const [recipeCoverImageId, setRecipeCoverImageId] = useState('');
    const [recipePortionCount, setRecipePortionCount] = useState(1);
    const [recipeCarbs, setRecipeCarbs] = useState(0);
    const [recipeCalorie, setRecipeCalorie] = useState(0);
    const [recipeFat, setRecipeFat] = useState(0);
    const [recipeProtein, setRecipeProtein] = useState(0);
    const [recipeWeight, setRecipeWeight] = useState(0);
    const [recipeImage, setRecipeImage] = useState(null);
    const [recipeImagePreview, setRecipeImagePreview] = useState(null);
    const [stepName, setStepName] = useState('');
    const [stepDescription, setStepDescription] = useState('');
    const [stepOrder, setStepOrder] = useState(1);
    const [stepImage, setStepImage] = useState(null);
    const [stepImagePreview, setStepImagePreview] = useState(null);
    const [stepImageId, setStepImageId] = useState('');
    const [stepId, setStepId] = useState('');
    const [productName, setProductName] = useState('');
    const [productId, setProductId] = useState('');
    const [productWeight, setProductWeight] = useState(null);
    const [products, setProducts] = useState([]);
    const storeProducts = [];//props.store.Products.products;
    const [finishProducts, setFinishProducts] = useState(false);
    const [finishSteps, setFinishSteps] = useState(false);
    const [isStepEdit, setStepEdit] = useState(false);
    const [stepIndex, setStepIndex] = useState(null);
    const [steps, setSteps] = useState([]);
    const [currentStep, setCurrentStep] = useState(0);

    const [finishRecipeId, setFinishRecipeId] = useState(null);
    const [finishProductStatus, setFinishProductStatus] = useState(false);
    const [finishStepsStatus, setFinishStepsStatus] = useState(false);

    const [runingFetch, setRuningFetch] = useState(false);
    const [init, setInit] = useState(false);

    const [recipeEdited, setRecipeEdited] = useState(false);
    const [recipeDeletedCover, setRecipeDeletedCover] = useState(false);
    const [stepDeletedCover, setStepDeletedCover] = useState(false);
    const [recipeCanClose, setRecipeCanClose] = useState(false);

    useEffect(() => {
        if (init) return;

        if (props.data !== undefined && props.data !== null) {
            const recipe = props.data;
            //setRecipe(props.data);
            setRecipeId(recipe.id);
            setRecipeName(recipe.name);
            setRecipeDescription(recipe.description);
            setRecipeExternalUrl(recipe.external_url);
            setRecipePortionCount(recipe.portion_count);
            setRecipeCoverImageId(recipe.cover_image_id);
            //setRecipeImagePreview(recipe.image !== null ? recipe.image.path : null);
            setRecipeUrlName(recipe.url_name);
            setRecipeIsPublic(recipe.is_public);

            setProducts(recipe.products);
            let sl = [...recipe.steps];
            sl.forEach(s => {
                s.image_preview = s.image !== null ? s.image.path : null;
            })
            setSteps(sl);
            setInit(true)
        }
    }, [init, props.data])

    function onChange(event) {
        switch(event.target.id) {
            case fieldIds.name:
                setRecipeName(event.target.value);
                setRecipeEdited(true);
                break;
            case fieldIds.description:
                setRecipeDescription(event.target.value);
                setRecipeEdited(true);
                break;
            case fieldIds.is_public:
                setRecipeIsPublic(event.target.value);
                setRecipeEdited(true);
                break;
            case fieldIds.external_url:
                setRecipeExternalUrl(event.target.value);
                setRecipeEdited(true);
                break;
            case fieldIds.url_name:
                setRecipeUrlName(event.target.value);
                setRecipeEdited(true);
                break;
            case fieldIds.cover_image_id:
                setRecipeCoverImageId(event.target.value);
                setRecipeEdited(true);
                break;
            case fieldIds.portion_count:
                setRecipePortionCount(event.target.value);
                setRecipeEdited(true);
                break;
            case fieldIds.image:
                setRecipeImage(event.target.files[0]);
                setRecipeEdited(true);
                setRecipeDeletedCover(true);
                break;
            case stepFieldIds.name:
                setStepName(event.target.value);
                break;
            case stepFieldIds.description:
                setStepDescription(event.target.value);
                break;
            case stepFieldIds.step_image:
                setStepImage(event.target.files[0]);
                break;
            case otherFieldIds.name:
                setProductName(event.target.value);
                break;
            case otherFieldIds.product_id:
                setProductId(event.target.value);
                break;
            case otherFieldIds.weight:
                setProductWeight(event.target.value);
                break;
            default:
                break;
        }
    }

    useEffect(() => {
        // create the preview
        if (stepImage !== null) {
        const objectUrl = URL.createObjectURL(stepImage)
        setStepImagePreview(objectUrl)
     
        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl)
        }
    }, [stepImage])

    useEffect(() => {
        // create the preview
        if (recipeImage !== null) {
            const objectUrl = URL.createObjectURL(recipeImage)
            setRecipeImagePreview(objectUrl)
        
            // free memory when ever this component is unmounted
            return () => URL.revokeObjectURL(objectUrl)
        }
    }, [recipeImage])

    useEffect(() => {
        if (recipeCanClose) {
            props.store.Recipes.loadingData();
            props.store.RecipeProducts.loadingData();
            props.store.RecipeSteps.loadingData();
            props.store.Images.loadingData();
            props.store.Global.setModal()
        }
    }, [recipeCanClose])

    useEffect(() => {
        let c = 0.0;
        let ugl = 0.0;
        let jir = 0.0;
        let protein = 0.0;
        let weight = 0.0;

        products.forEach(p => {
            c += p.product.calorie/100*p.weight;
            ugl += p.product.carbs/100*p.weight;
            jir += p.product.fat/100*p.weight;
            protein += p.product.protein/100*p.weight;
            weight += p.weight;
        })

        setRecipeCalorie(c);
        setRecipeCarbs(ugl);
        setRecipeFat(jir);
        setRecipeProtein(protein);
        setRecipeWeight(weight);
    }, [products.length])

    function update() {
        setRuningFetch(true);
        const data = new FormData();

        if (recipeDeletedCover) {
            data.append('file', recipeImage);
        }
        data.append('id', recipeId);
        data.append('external_url',recipeExternalUrl);
        data.append('name',recipeName);
        data.append('description',recipeDescription);
        data.append('url_name',recipeUrlName);
        data.append('is_public',recipeIsPublic);
        data.append('portion_count',recipePortionCount);
        data.append('cover_image_id', recipeCoverImageId);

        props.store.Recipes.update(data)
        .then(result => {
            if (result.data.status) {
                setRecipeCanClose(true);
            }
            else {
                setRuningFetch(false);
                toast.error('error');
                return;
            }
        }).catch(error => {
            setRuningFetch(false);
            toast.error(error)
            return;
        })
    }

    function fetchCreateProducts(data) {
        setRuningFetch(true);
        if (data === null || data === undefined) {
            setRuningFetch(false);
            toast.error('Не найден рецепт')
            return;
        }
        setFinishRecipeId(data);
        
        let productsData = [...products];
        productsData.forEach((product, index) => {
            product.recipe_id = finishRecipeId;
        })
        props.store.RecipeProducts.create({products: productsData})
        .then(resultProducts => {
            if (resultProducts.data.status) {
                fetchCreateSteps(true);
                
            }
            else {
                setRuningFetch(false);
                toast.error('error');
                return;
            }
        })
        .catch(error => {
            setRuningFetch(false);
            toast.error(error)
            return;
        })
    }

    function fetchCreateSteps(data) {
        setRuningFetch(true);
        if (data === null || data === undefined) {
            setRuningFetch(false);
            toast.error('Не добавлены продукты')
            return;
        }
        setFinishProductStatus(true);

        let stepsData = [...steps];
        let promisesList = [];
        stepsData.forEach((step, index) => {
            step.step_order = index + 1;
            const imageData = new FormData();
            imageData.append('file', step.image);
            imageData.append('recipe_id', finishRecipeId);
            imageData.append('step_order', index + 1);
            imageData.append('name', step.name);
            imageData.append('description', step.description);

            promisesList.push(props.store.RecipeSteps.create(imageData));
        })

        Promise.all(promisesList)
        .then(r => {
            setFinishStepsStatus(true);
            setRuningFetch(false);
        })
        .catch(error => {
            setRuningFetch(false);
            toast.error(error)
            return;
        })
    }

    function addStep() {
        let step = {
            step_order: stepIndex + 1,
            name: stepName,
            description: stepDescription,
            image: stepImage,
            image_preview: stepImagePreview,
            image_id: stepImageId,
            id: stepId
        }

        let currentSteps = [...steps];
        if (stepIndex !== null) {
            const imageData = new FormData();
            if (stepDeletedCover) {
                imageData.append('file', stepImage);
            }
            imageData.append('id', stepId);
            imageData.append('image_id', stepImageId)
            imageData.append('recipe_id', recipeId);
            imageData.append('step_order', stepIndex + 1);
            imageData.append('name', stepName);
            imageData.append('description', stepDescription);

            props.store.RecipeSteps.update(imageData)
            .then(result => {
                if (result.data.status) {
                    currentSteps[stepIndex] = step;
                    setSteps(currentSteps);

                    setStepName('');
                    setStepDescription('');
                    setStepImage(null);
                    setStepImagePreview(null);
                    setStepEdit(false);
                    setStepIndex(null);
                    setStepId('');
                }
                else {
                    toast.error('Шаг не был обновлен')
                    return;
                }
            })
            .catch(error => {
                toast.error('Шаг не был обновлен')
                return;
            })

            
        }
        else {
            const imageData = new FormData();
            imageData.append('id', stepId);
            imageData.append('file', stepImage);
            imageData.append('image_id', stepImageId)
            imageData.append('recipe_id', recipeId);
            imageData.append('step_order', steps.length + 1);
            imageData.append('name', stepName);
            imageData.append('description', stepDescription);

            props.store.RecipeSteps.create(imageData)
            .then(result => {
                if (result.data.status) {
                    step.id = result.data.data.id;
                    currentSteps.push(step);
                    setSteps(currentSteps);
                    props.store.RecipeSteps.loadingData();

                    setStepName('');
                    setStepDescription('');
                    setStepImage(null);
                    setStepImagePreview(null);
                    setStepEdit(false);
                    setStepIndex(null);
                    setStepId('');
                }
                else {
                    toast.error('Шаг не был добавлен')
                    return;
                }
            })
            .catch(error => {
                toast.error('Шаг не был добавлен')
                return;
            })

            
        }
        
    }

    function editStep(data, index) {
        setStepIndex(index);
        setStepEdit(true);
        setStepName(data.name);
        setStepDescription(data.description);
        //setStepImage(data.image);
        setStepImagePreview(data.image_preview);
        setStepImageId(data.image_id);
        setStepId(data.id);
    }

    function deleteStep(id, index) {
        props.store.RecipeSteps.delete(id)
        .then(result => {
            if (result.data.status) {
                let currentSteps = [...steps];
                currentSteps.splice(index, 1);
                setSteps(currentSteps);
            }
            else {
                toast.error('Шаг не был удален')
            }
        })
        .catch(error => {
            toast.error(error);
        })
    }

    function cancelStep() {
        setStepName('');
        setStepDescription('');
        setStepImage(null);
        setStepImagePreview(null);
        setStepEdit(false);
        setStepIndex(null);
        setStepId('');
    }

    function addProduct() {
        setRuningFetch(true);
        const currentProducts = [...products];
        const findedProduct = storeProducts.find(p=> p.id === productId);
        const data = {
            recipe_id: recipeId,
            name: findedProduct.name,
            product_id: productId,
            weight: productWeight,
            product: findedProduct
        }

        props.store.RecipeProducts.create({products: [data]})
        .then(result => {
            if (result.data.status) {
                setRuningFetch(false);
                currentProducts.push(data);
                setProducts(currentProducts);
                setProductId('');
                setProductName('');
                setProductWeight(0);
            }
            else {
                setRuningFetch(false);
                toast.error('Ингредиент не был добавлен')
            }
        })
        .catch(error => {
            setRuningFetch(false);
            toast.error(error);
        })
    }

    function deleteProduct(data, index) {
        props.store.RecipeProducts.delete(data.id)
        .then(result => {
            if (result.data.status) {
                let currentProducts = [...products];
                currentProducts.splice(index,1);
                setProducts(currentProducts);
            }
            else {
                toast.error('Ингредиент не был удален')
            }
        })
        .catch(error => {
            toast.error(error);
        })
    }

    function changePortionCount(value) {
        if (recipePortionCount + value === 0) {
            return;
        }

        setRecipePortionCount(recipePortionCount + value);
        setRecipeEdited(true);
    }

    function deleteImagePreview(type) {
        if (type === 0) {
            setRecipeImage(null);
            setRecipeImagePreview(null);
            setRecipeCoverImageId(null);
            setRecipeEdited(true);
        }

        if (type === 1) {
            setStepImage(null);
            setStepImagePreview(null);
            setStepDeletedCover(true);
        }
    }

    return <Component>
        <RecipeProgressSection>
            <RecipeProgressButton isActive={currentStep === 0} onClick={() => setCurrentStep(0)}>Информация о рецепте</RecipeProgressButton>
            <RecipeProgressButton isActive={currentStep === 1} onClick={() => setCurrentStep(1)}>Описание приготовления</RecipeProgressButton>
        </RecipeProgressSection>
        <ContentSection>
        <MainSection isVisible={currentStep === 0}>
            <Columns>
            <FileType>
                {
                    recipeImagePreview === null && <>
                        <StyledLogo />
                        <ImageAlt>Добавить обложку</ImageAlt>
                    </>
                }
                {   
                    recipeImagePreview !== null && <ImagePreview src={recipeImagePreview}/>
                }
                <FileUpload type='file' id={fieldIds.image} onChange={(event) => onChange(event)}/>
                {   
                    recipeImagePreview !== null && <DeleteButton absolute={true} onClick={() => deleteImagePreview(0)}>Удалить</DeleteButton>
                }
            </FileType>
            <Input type='text' id={fieldIds.name} value={recipeName} label='Название' onChange={(event => onChange(event))}/>
            <Textarea type='text' id={fieldIds.description} value={recipeDescription} rows='3' label='Описание' onChange={(event => onChange(event))}/>
            <Input type='url' id={fieldIds.external_url} value={recipeExternalUrl} label='Внешняя ссылка на рецепт' onChange={(event => onChange(event))}/>
            </Columns>
            <Columns>
            {/* <Input type='text' id={fieldIds.url_name} value={recipeUrlName} label='Имя урл' onChange={(event => onChange(event))}/> */}
            <Title>Энергетическая ценность</Title>
            <NutrientsList>
                <NutrientItem>
                    <NutrientTitle>Калории</NutrientTitle>
                    <NutrientValue color='#1e7390'>{Number((recipeCalorie/recipePortionCount).toFixed(2)).toString()} ({recipeCalorie})</NutrientValue>
                </NutrientItem>
                <NutrientItem>
                    <NutrientTitle>Белки</NutrientTitle>
                    <NutrientValue color='#07bc0c'>{Number((recipeProtein/recipePortionCount).toFixed(2)).toString()} ({recipeProtein})</NutrientValue>
                </NutrientItem>
                <NutrientItem>
                    <NutrientTitle>Жиры</NutrientTitle>
                    <NutrientValue color={Colors.peach}>{Number((recipeFat/recipePortionCount).toFixed(2)).toString()} ({recipeFat})</NutrientValue>
                </NutrientItem>
                <NutrientItem>
                    <NutrientTitle>Углеводы</NutrientTitle>
                    <NutrientValue color={Colors.red}>{Number((recipeCarbs/recipePortionCount).toFixed(2)).toString()} ({recipeCarbs})</NutrientValue>
                </NutrientItem>
            </NutrientsList>
            <SmallText>Значение на 1 порцию (общее значение)</SmallText>
            <ProductHeader>
                <Title>Ингредиенты</Title>
                <ProductHeaderButtons>
                    <ProductHeaderText>Порции</ProductHeaderText>
                    <ProductHeaderButton onClick={() => changePortionCount(-1)}>-</ProductHeaderButton>
                    <span>{recipePortionCount}</span>
                    <ProductHeaderButton onClick={() => changePortionCount(1)}>+</ProductHeaderButton>
                    
                </ProductHeaderButtons>
            </ProductHeader>
            <ProductList>
            {
                products.map((product, index) => <ProductItem>
                    <span>{product.name}</span>
                    <ProductItemLine />
                    <span>{product.weight} г.</span>
                    {/* <Button type='delete' onClick={() => deleteProduct(index)}>Удалить</Button> */}
                    <DeleteButton type='delete' onClick={() => deleteProduct(index)}/>
                </ProductItem>)
            }
            {
                products.length === 0 && <EmptyPage>Тут пока пусто</EmptyPage>
            }
            </ProductList>
            <Title>Добавить ингредиент</Title>
            <ProductHeaderButtons>
                <Select id={otherFieldIds.product_id} value={productId} onChange={(event) => onChange(event)}>
                    <option key={0} value={null} hidden>Выберите продукт</option>
                {
                    storeProducts.map(p => <option key={p.id} value={p.id}>{p.name}</option>)
                }
                </Select>
                <Input type='number' maxWidth='30%' id={otherFieldIds.weight} value={productWeight} label='Вес, г.' onChange={(event => onChange(event))}/>
                <ProductHeaderButton onClick={() => addProduct()}>+</ProductHeaderButton>
            </ProductHeaderButtons>
            </Columns>
        </MainSection>
        <AddStepSection isVisible={currentStep === 1}>
        <Title>Добавить шаг</Title>
            <FileType>
                {
                    stepImagePreview === null && <>
                        <StyledLogo />
                        <ImageAlt>Добавить фотографию</ImageAlt>
                    </>
                }
                {   
                    stepImagePreview !== null && <ImagePreview src={stepImagePreview}/>
                }
                <FileUpload type='file' id={stepFieldIds.step_image} onChange={(event) => onChange(event)}/>
                {   
                    stepImagePreview !== null && <DeleteButton onClick={() => deleteImagePreview(1)}>Удалить</DeleteButton>
                }
            </FileType>
            <Input type='text' id={stepFieldIds.name} value={stepName} label='Название шага' onChange={(event => onChange(event))}/>
            <Textarea type='text' id={stepFieldIds.description} value={stepDescription} rows='5' label='Описание' onChange={(event => onChange(event))}/>
            <Centered>
                <Button onClick={() => addStep()}>{stepIndex !== null ? 'Изменить' : 'Добавить'} шаг</Button>
                {
                    stepIndex !== null && <Button onClick={() => cancelStep()}>Отмена</Button>
                }
            </Centered>
        </AddStepSection>
        <StepSection  isVisible={currentStep === 1}>
        <Title>Процесс приготовления</Title>
            <StepList>
            {
                steps.map((step, index) => <StepItem>
                    <ProductHeader>
                        <Title>Шаг {index + 1}. {step.name}</Title>
                        
                    </ProductHeader>
                    {step.image_preview !== null && <ImagePreview centered src={step.image_preview}/>}
                    <SmallText>{step.description}</SmallText>
                    <Centered>
                        <Button onClick={() => editStep(step, index)}>Редактировать</Button>
                        <Button onClick={() => deleteStep(index)} type='delete'>Удалить</Button>
                    </Centered>
                </StepItem>)

                
            }
            {
                steps.length === 0 && <EmptyPage>Тут пока пусто</EmptyPage>
            }
            </StepList>
        </StepSection>
        </ContentSection>
        {
            recipeEdited && <Centered>
            <Button onClick={() => update()}>Сохранить</Button>
            
    </Centered>
        }
        
        { runingFetch && <Loading><LoadingText>Обработка данных...</LoadingText></Loading> }
    </Component>
}

export default inject("store")(Modal);