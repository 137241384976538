import { Routes, Route } from "react-router-dom";
import styled from 'styled-components';
import Main from './pages/main';
import Header from './components/header';
import { observer } from 'mobx-react-lite';
import { inject } from 'mobx-react';
import * as vars from './constants';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modal from './components/modal';
import Exercises from './pages/exercises';
import Workouts from './pages/main';
import Auth from './pages/auth';
import { Outlet } from "react-router-dom";
import Dialog from "./components/dialog";


const Page = styled.div`
	flex-direction: column;
	display: flex;
	//background-color: ${vars.Colors.light_green};
	background: #e0f5fd;//linear-gradient(147deg, ${vars.Colors.primary}, ${vars.Colors.secondary});
	height: 100%;
`;
const Content = styled.div`
	flex: 1;
    display: flex;
	overflow: auto;
    height: calc(100% - 50px);
`;

function App(props) {
	if (!props.store.User.is_signin) {
		return <>
			<ToastContainer
				position="top-right"
				autoClose={2000}
				hideProgressBar={true}
				newestOnTop
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				limit={10}/>
			<Auth />
		</>
	}

	return (
		<Page>
			<ToastContainer
				position="top-right"
				autoClose={2000}
				hideProgressBar={true}
				newestOnTop
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				limit={10}/>
			<Modal {...props}/>
			<Header {...props}/>
			<Content>
                <Outlet />
            </Content>
			{ props.store.Global.showDialog && <Dialog props={props.store.Global.dialogProps}/> }
		</Page>
	);
}

export default inject("store")(observer(App));
