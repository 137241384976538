import { makeObservable, observable, action, makeAutoObservable, computed } from "mobx";
import axios from 'axios';
import { getToken } from '../utils';
import { Config } from '../constants';
import { toast } from 'react-toastify';
const NODE_ENV = process.env.NODE_ENV || 'production';

class Data {
    isLoading = false;

    diaries = [];
    exercises = [];
    images = [];
    weights = [];
    workouts = [];

    constructor() {
        makeObservable(this, {
            diaries: observable,
            exercises: observable,
            images: observable,
            weights: observable,
            workouts: observable,
            works: computed,
            fetch: action,
            loadWeights: action
        });

        this.fetch();
    }

    async fetch() {
        this.isLoading = true;
        
        let diariesResponse,
            diaryItemsResponse,
            diaryPhotosResponse,
            exercisesResponse,
            exerciseItemsResponse,
            imagesResponse,
            weightsResponse,
            workoutsResponse,
            workoutItemsResponse;

        try {
            diariesResponse = await axios.get(Config.api.host.fitness[NODE_ENV]+Config.api.diaries.get_all);
            diaryItemsResponse = await axios.get(Config.api.host.fitness[NODE_ENV]+Config.api.diary_items.get_all);
            diaryPhotosResponse = await axios.get(Config.api.host.fitness[NODE_ENV]+Config.api.diary_photos.get_all);
            exercisesResponse = await axios.get(Config.api.host.fitness[NODE_ENV]+Config.api.exercises.get_all);
            exerciseItemsResponse = await axios.get(Config.api.host.fitness[NODE_ENV]+Config.api.exercise_items.get_all);
            imagesResponse = await axios.get(Config.api.host.fitness[NODE_ENV]+Config.api.images.get_all);
            weightsResponse = await axios.get(Config.api.host.fitness[NODE_ENV]+Config.api.weights.get_all);
            workoutsResponse = await axios.get(Config.api.host.fitness[NODE_ENV]+Config.api.workouts.get_all);
            workoutItemsResponse = await axios.get(Config.api.host.fitness[NODE_ENV]+Config.api.workout_items.get_all);
        }
        catch (error) {
            this.isLoading = false;
            toast.error(error?.response?.data?.message || error.message);
            return;
        }

        // images section
        let images = [...this.getProcessedImageArray(imagesResponse.data.data)];

        // exercise section
        let exercises = [...this.getProcessedExercises(exercisesResponse.data.data, exerciseItemsResponse.data.data, images)];

        // workout section
        let workouts = [...this.getProcessedWorkouts(workoutsResponse.data.data, workoutItemsResponse.data.data, images, exercises)];

        // diaries section
        let diaries = [...this.getProcessedDiaries(diariesResponse.data.data, diaryItemsResponse.data.data, diaryPhotosResponse.data.data, images, workouts)];

        // weight section
        let weights = [...this.getProcessedWeights(weightsResponse.data.data)];
    
        this.diaries = diaries;
        this.exercises = exercises;
        this.images = images;
        this.weights = weights;
        this.workouts = workouts;
        this.isLoading = false;
    }

    get works() {

    }

    get currentWeight() {
        return this.weights.length > 0 ? this.weights[this.weights.length-1].weight+'кг' : '🤷';
    }

    async loadWeights() {
        let weightsResponse;
        try {
            weightsResponse = await axios.get(Config.api.host.fitness[NODE_ENV]+Config.api.weights.get_all);
        }
        catch (error) {
            this.isLoading = false;
            toast.error(error?.response?.data?.message || error.message);
            return;
        }

        let weights = [...this.getProcessedWeights(weightsResponse.data.data)];
        this.weights = weights;
    }

    create(type, data) {
        return axios.post(Config.api.host.fitness[NODE_ENV]+Config.api[type].create, data);
    }

    update(type, data) {
        return axios.put(Config.api.host.fitness[NODE_ENV]+Config.api[type].update, data);
    }

    delete(type, id) {
        return axios.delete(Config.api.host.fitness[NODE_ENV]+Config.api[type].delete+id);
    }

    getProcessedWeights(weightArray) {
        let current;
        weightArray.forEach(weight => {
            if (!current) {
                current = weight.weight;
            }

            weight.diff = weight.weight-current;
            current = weight.weight;
        });

        return weightArray;
    }

    getProcessedImageArray(imagesArray) {
        let pref = '';
        imagesArray.forEach(image => {
            if (!image.path.startsWith('http')) {
                pref = 'https://fitness.tsvetkov.one/static/';
            }
            image.path = pref+image.path;
        });

        return imagesArray;
    }

    getProcessedExercises(exerciseArray, itemArray, imageArray) {
        let exercises = [];
        let steps;
        let findedImage;
        exerciseArray.forEach(exercise => {
            exercise.image = null;
            if (exercise.image_id !== null) {
                findedImage = imageArray.find(image => image.id === exercise.image_id);
                if (findedImage !== undefined) {
                    exercise.image = Object.create(findedImage);
                }
            }

            steps = [];
            itemArray
            .filter(step => step.exercise_id === exercise.id)
            .forEach(step => {
                step.image = null;
                if (step.image_id !== null) {
                    findedImage = imageArray.find(image => image.id === step.image_id);
                    if (findedImage !== undefined) {
                        step.image = Object.create(findedImage);
                    }
                }
                steps.push(step);
            })

            exercise.steps = [...steps];
            exercises.push(exercise);
        });

        return exercises;
    }

    getProcessedWorkouts(workoutArray, itemArray, imageArray, exerciseArray) {
        let workouts = [];
        let findedExercise;
        let findedImage;
        let steps;
        workoutArray.forEach(workout => {
            workout.image = null;
            if (workout.image_id !== null) {
                findedImage = imageArray.find(image => image.id === workout.image_id);
                if (findedImage !== undefined) {
                    workout.image = Object.create(findedImage);
                }
            }

            steps = [];
            itemArray
            .filter(step => step.workout_id === workout.id)
            .forEach(step => {
                step.image = null;
                if (step.image_id !== null) {
                    findedImage = imageArray.find(image => image.id === step.image_id);
                    if (findedImage !== undefined) {
                        step.image = Object.create(findedImage);
                    }
                }

                step.exercise = null;
                if (step.exercise_id !== null) {
                    findedExercise = exerciseArray.find(exercise => exercise.id === step.exercise_id);
                    if (findedExercise !== undefined) {
                        step.exercise = Object.create(findedExercise);
                    }
                }

                steps.push(step);
            })

            workout.steps = [...steps];
            workouts.push(workout);
        });

        return workouts;
    }

    getProcessedDiaries(diaryArray, itemArray, photoArray, imageArray, workoutArray) {
        let diaries = [];
        let photos;
        let steps;
        let findedWorkout;
        let findedImage;
        diaryArray.forEach(diary => {
            diary.workout = null;
            if (diary.workoutId !== null && diary.workoutId !== '') {
                findedWorkout = workoutArray.find(workout => workout.id === diary.workoutId);
                if (findedWorkout !== undefined) {
                    diary.workout = Object.create(findedWorkout);
                }
            }

            steps = [];
            photos = [];
            itemArray
            .filter(step => step.diaryId === diary.id)
            .forEach(step => {
                steps.push(step);
            })

            photoArray
            .filter(step => step.diaryId === diary.id)
            .forEach(step => {
                step.image = null;
                if (step.imageId !== null) {
                    findedImage = imageArray.find(image => image.id === step.imageId);
                    if (findedImage !== undefined) {
                        step.image = Object.create(findedImage);
                    }
                }

                photos.push(step);
            })

            diary.steps = [...steps];
            diary.photos = [...photos];
            diaries.push(diary);
        });

        return diaries;
    }
}

const dataStore = new Data();
export { dataStore }