
import CreateRecipe from './create_workout';
import EditRecipe from './edit_recipe';
import { Modals } from '../../constants';
import Dialog from './dialog';
import CreateWeight from './create-weight';
import CreateDiary from './create_diary';

export default function Component(props) {
    const type = props.store.Global.modalType;
    const data = props.store.Global.modalData;

    switch(type) {
        case Modals.create_workout.name:
            return <CreateRecipe {...props}/>;
        case Modals.edit_exercise.name:
            return <EditRecipe {...props} data={data}/>;
        case Modals.edit_menu.name:
            return <Dialog {...props} data={data}/>;
        case Modals.create_weight.name:
            return <CreateWeight {...props} data={data}/>;
        case Modals.create_diary.name:
            return <CreateDiary {...props} data={data} />;
        default:
            return null;
    }
}