import { makeObservable, observable, action } from "mobx";
import axios from 'axios';
import { getToken } from '../utils';
import { Config } from '../constants';
import { toast } from 'react-toastify';

const NODE_ENV = process.env.NODE_ENV || 'production';
class Images {
    images = [];
    mapImages = [];

    constructor(title) { 
        makeObservable(this, {
            images: observable,
            mapImages: observable,
            loadingData: action,
            create: action,
            upload: action,
            setImages: action,
            setMapImages: action
        })

        this.loadingData = this.loadingData.bind(this);
        this.create = this.create.bind(this);
        this.upload = this.upload.bind(this);

        axios.defaults.headers.common['x-user-language'] = 'ru';
        axios.defaults.headers.common['x-app-name'] = 'fitness';
        axios.defaults.headers.common['x-access-token'] = getToken();
        this.loadingData();
    }

    async loadingData() {
        /*
        axios.get(Config.api.host.fitness[NODE_ENV]+Config.api.images.get_all)
        .then(result => {
            if (result.data.status) {
                this.images = result.data.data;
            }
            else {
                toast.error(result.data.message)
            }
        })
        .catch(error => {
            toast.error(error)
        })
        */

        let imagesResponse;
        try {
            imagesResponse = await axios.get(Config.api.host.fitness[NODE_ENV]+Config.api.images.get_all);
        }
        catch (error) {
            toast.error(error?.response?.data?.message || error.message);
            return;
        }

        if (!imagesResponse.data.status) {
            toast.error(imagesResponse.data.message);
            return;
        }

        let images = {};
        imagesResponse.data.data.forEach(image => {
            images[image.id] = image;
            let pref = '';
            if (!image.path.startsWith('http')) {
                pref = 'http://localhost:8140/static/';
            }
            image.path = pref+image.path;
        });

        this.setImages(imagesResponse.data.data);
        this.setMapImages(images)
    }

    setImages(data) {
        this.images = data;
    }

    setMapImages(data) {
        this.mapImages = data;
    }

    create(data) {
        return axios.post(Config.api.host.fitness[NODE_ENV]+Config.api.images.create, data);
    }

    upload(data) {
        return axios.post(Config.api.host.fitness[NODE_ENV]+Config.api.images.upload, data);
    }
}

export default new Images();