import { useState } from "react";
import { useEffect, useRef} from "react";
import { useParams, useNavigate } from "react-router-dom";
import global from "../../store/global";
import Button from "../button";
import Input from "../input_material";
import styled from "styled-components";
import { Config, Colors } from "../../constants";
import Image from "../image_preview";
import { toast } from "react-toastify";
import { dataStore } from "../../store/data";
import { observer } from "mobx-react";
import { v4 } from 'uuid';
import Imag from '../../store/images';
import Select from "../select";

const Component = styled.div`
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    display: flex;
    padding: 1rem;
`;
const InputContainer = styled.button`
    position: relative;
    //margin: 0 auto;
    border: 1px solid ${Colors.primary};
    border-radius: .25rem;
    width: fit-content;
    padding: .5rem;
    background: ${Colors.primary};
    color: white;
    cursor: pointer;
`;
const ImageInput = styled.input`
    width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
`;
const PhotoList = styled.div`
    padding: 1rem;
    flex-direction: row;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
`;
const Row = styled.div`
    display: flex;
`;
const EmojiButton = styled.button`
    justify-content: center;
    display: flex;
    border: none;
    border-radius: .5rem;
    padding: .5rem;
    font-size: 2rem;
    background: none;
    outline: ${props => props.selected ? '2px solid '+Colors.primary : 'none'};
    cursor: pointer;

    &:hover {
        background-color: ${Colors.primary};
    }

    &:focus {
        outline: 2px solid ${Colors.primary};
    }
`;
function CreateDiary(props) {
    const { workouts } = dataStore;

    const [workout, setWorkout] = useState(null);
    const [date, setDate] = useState(null);
    function addPhoto(photo) {
        let p = [...photos];
        p.push(photo);
        setPhotos(p);
    }

    function removePhoto(index) {
        let p = [...photos];
        p.splice(index, 1);
        setPhotos(p);
    }

    async function create() {
        if (workout === null || workout === '') {
            toast.error('Не выбрана тренировка');
            return;
        }

        let diaryId;
        try {
            const response = await dataStore.create(Config.DIARIES, {
                workoutId: workout,
                feel: feel,
                createdByUser: true,
                createdAt: date,
            });
            if (!response.data.status) {
                toast.error(response.data.message);
                return;
            }

            diaryId = response.data.data.id;
        }
        catch (error) {
            toast.error(error?.response?.data?.message || error.message);
            return;
        };

        if (photos.length > 0) {
            try {
                let coverId = null;
                let data = new FormData();
                for (let photo of photos) {
                    data = new FormData();
                    coverId = v4();
                    data.append('file',photo);
                    data.append('id', coverId);
                    const response = await Imag.upload(data);
                    if (!response.data.status) {
                        toast.error(response.data.message);
                        return;
                    }

                    data = new FormData();
                    data.append('diaryId', diaryId);
                    data.append('imageId', coverId);
                    data.append('photoComment', '');

                    const stepResponse = await dataStore.create(Config.DIARYPHOTOS, data);
                    if (!stepResponse.data.status) {
                        toast.error(stepResponse.data.message);
                        return;
                    }
                }
            }
            catch (error) {
                toast.error(error?.response?.data?.message || error.message);
                return;
            };
        }

        await dataStore.fetch();
        global.setModal();
    }
    const [feel, setFeel] = useState(null); 
    const feelRef = useRef(null); // создаем объект useRef
    useEffect(() => { // обновляем feelRef.current при изменении feel 
        feelRef.current = feel; 
    }, [feel]);
    const [photos, setPhotos] = useState([]);
    return <Component>
        <Input type='date' value={date} onChange={(event) => setDate(event.target.value)} />
        <Select style={{padding: '.25rem'}} value={workout} onChange={(event) => setWorkout(event.target.value)}>
            <option key={v4()} value=''>Выберите тренировку</option>
        {
            workouts.map(e => <option key={e.id} value={e.id}>{e.name}</option>)
        }
        </Select>
        <Row>
            <EmojiButton onClick={() => setFeel(-1)}>🫠</EmojiButton>
            <EmojiButton onClick={() => setFeel(0)}>🙂</EmojiButton>
            <EmojiButton onClick={() => setFeel(1)}>😏</EmojiButton>
        </Row>
        {
            photos.length < 5 && <InputContainer>
                <ImageInput type='file' id='cover' onChange={(event) => addPhoto(event.target.files[0])}/>
                <label for='cover'>Добавить фотографии в дневник</label>
            </InputContainer>
        }
        
        <PhotoList>
        {
            photos.length > 0 && photos.map((photo, index) => <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                <Image data={photo} width='200px' height='200px'/>    
                <span onClick={() => removePhoto(index)} style={{color: 'red', fontSize: 'smaller', cursor: 'pointer'}}>Удалить</span>
                </div>
            )
        }
        </PhotoList>
        <Button onClick={() => create()}>Добавить</Button>
    </Component>
}

export default observer(CreateDiary);