import { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import { toJS } from "mobx";
const anim = keyframes`
    from {
        background-color: transparent;
    }
    to {
        background-color: rgba(0,0,0,0.5);
    }
`;
const Overflow = styled.div`
    content: " ";
    position: fixed;
    justify-content: center;
    align-items: center;
    display: flex;
    height: 100%;
    width: 100%;
    background-color: rgba(0,0,0,0.5);
    animation: ${anim} 0.3s ease-out;

`;
const Modal = styled.div`
    flex-direction: column;
    display: flex;
    align-items: center;
    border-radius: .25rem;
    background-color: white;
`;
const Header = styled.h2`
    margin: 0;
    width: 100%;
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 1.6;
    letter-spacing: 0.0075em;
    padding: 1rem 1.5rem;
    flex: 0 0 auto;
`;
const Text = styled.p`
    margin: 0;
    padding: 1rem 1.5rem;
    padding-top: 0;
    text-align: start;
    width: 100%;
`;
const ButtonSection = styled.div`
    justify-content: end;
    align-items: center;
    gap: 1rem;
    display: flex;
    width: 100%;
    padding: .5rem;
`;
const Button = styled.button`
    border: 0;
    border-radius: .25rem;
    padding: .25rem .5rem;
    background-color: transparent;
    outline: none;
    min-width: 32px;
    text-transform: uppercase;
    cursor: pointer;

    &:hover {
        background-color: rgb(123 213 245 / 20%);
    }
`;
function Dialog(props) {
    return <Overflow>
        <Modal>
            <Header>{props.props.title}</Header>
            <Text>{props.props.message}</Text>
            <ButtonSection>
                <Button onClick={() => props.props.positiveClick()}>{props.props.positiveButtonName || 'Да'}</Button>
                <Button onClick={() => props.props.negativeClick()}>{props.props.negativeButtonName || 'Нет'}</Button>
            </ButtonSection>
        </Modal>
    </Overflow>
}

export default Dialog;