import { useState } from "react";
import { dataStore } from "../../store/data";
import global from "../../store/global";
import Button from "../button";
import Input from "../input_material";
import styled from "styled-components";
import { Config } from "../../constants";
import { toast } from "react-toastify";

const Component = styled.div`
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    display: flex;
    padding: 1rem;
`;

function CreateWeight(props) {
    const [weight, setWeight] = useState('');
    async function add() {
        if (weight <= 0) {
            toast.error('Введен некорректный вес');
            setWeight('');
            return;
        }

        try {
            const response = await dataStore.create(Config.WEIGHTS, {
                weight: weight
            });
            if (!response.data.status) {
                toast.error(response.data.message);
                return;
            }
        }
        catch (error) {
            toast.error(error?.response?.data?.message || error.message);
            return;
        };

        await dataStore.loadWeights();

        global.setModal();
    }

    return <Component>
        <Input label='Вес' type='number' value={weight} onChange={(event) => setWeight(event.target.value)} />
        <Button onClick={() => add()}>Добавить</Button>
    </Component>
}

export default CreateWeight;