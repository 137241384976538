import styled, { keyframes } from 'styled-components';
import { inject, observer } from "mobx-react";
import { Colors, Device, Modals } from '../../constants';
import ModalComponent from './modals';

const anim = keyframes`
    from {
        background-color: transparent;
    }
    to {
        background-color: rgba(0,0,0,0.2);
    }
`;
const Overflow = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0,0,0,0.2);
    animation: ${anim} 0.3s ease-out;
    z-index: 1100;
`;
const Component = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    flex-direction: column;
    display: flex;
    transform: translate(-50%, -50%);
    border: 1px solid lightgray;
    border-radius: .5rem;
    background-color: white;
    min-width: 300px;
    max-width: 90vw;
    max-height: 90vh;
    z-index: 1000;
`;
const HeaderSection = styled.div`
    padding: .5rem .5rem;
    border-bottom: 1px solid lightgray;
    font-family: 'Amatic SC', cursive;
    font-size: 2rem;
    justify-content: center;
    display: flex;
    align-items: center;
    height: 50px;

    @media ${Device.mobileS} {
        font-size: 1.5rem;
    }

    @media ${Device.mobileM} {
        font-size: 1.5rem;
    }

    @media ${Device.mobileL} {
        font-size: 1.5rem;
    }

    @media ${Device.tablet} {
        font-size: 1.5rem;
    }

    @media ${Device.laptopS} {
        font-size: 1.3rem;
    }

    @media ${Device.laptopM} {
        font-size: large;
    }

    @media ${Device.laptopL} {
        //font-size: 1rem;
    }
`;
const CloseButton = styled.button`
position: absolute;
top: 0;
right: 0.5rem;
    height: 50px;
    border: none;
    outline: none;
    background-color: transparent;
    font-size: 1.25rem;
    &:hover {
        color: ${Colors.red}
    }
`;
function Modal(props) {
    const type = props.store.Global.modalType;
    const data = props.store.Global.modalData;

    if (type === 'none') 
        return null; 

    return <Overflow>
        <Component>
            <HeaderSection>
                <span>{Modals[type]?.label || data?.label}</span>
                <CloseButton  onClick={() => props.store.Global.setModal()}>X</CloseButton>
            </HeaderSection>
            <ModalComponent {...props}/>
        </Component>
    </Overflow>
}

export default observer(Modal);