import styled from "styled-components";
import Input from "../../components/input_material";
import Textarea from "../../components/textarea_material";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { inject } from 'mobx-react';
import { Colors, Device } from "../../constants";
import Button from '../../components/button';
import Image from "../../components/image_preview";
import { v4 } from 'uuid';

const Component = styled.div`
    flex-direction: column;
    gap: 1rem;
    display: flex;
    width: 800px;
    margin: 1rem auto;
    overflow: auto;
    background-color: white;
    padding: 1rem;
    border-radius: .25rem;
`;
const InputContainer = styled.button`
    position: relative;
    //margin: 0 auto;
    border: 1px solid ${Colors.primary};
    border-radius: .25rem;
    width: fit-content;
    padding: .5rem;
    background: ${Colors.primary};
    color: white;
    cursor: pointer;
`;
const ImageInput = styled.input`
    width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
`;
const Group = styled.div`
    flex-direction: column;
    gap: 1rem;
    display: flex;
    border: 1px solid lightgrey;
    border-radius: .25rem;
    padding: 1rem;

`;
const ImagePreview = styled.img`
    border-radius: .25rem;
    width: 400px;
    height: 200px;
    margin: ${props => props.centered !== undefined ? '0 auto' : '0'};
    object-fit: cover;

    @media ${Device.mobileS} {
        width: 100%;
        height: 200px;
    }

    @media ${Device.mobileM} {
        width: 100%;
        height: 200px;
    }

    @media ${Device.mobileL} {
        width: 100%;
        height: 200px;
    }

    @media ${Device.tablet} {
        width: 300px;
        height: 150px;
    }

    @media ${Device.laptopS} {
        width: 300px;
        height: 150px;
    }

    @media ${Device.laptopM} {
        width: 400px;
        height: 200px;
    }

    @media ${Device.laptopL} {
        width: 400px;
        height: 200px;
    }
`;
const Centered = styled.div`
    justify-content: center;
    align-items: center;
    gap: 1rem;
    display: flex;
`;
const EmojiButton = styled.span`
    cursor: pointer;
`;
const StepList = styled.div`
    flex-direction: column;
    gap: 1rem;
    display: flex;
`;
const StepTitle = styled.span`
    font-size: larger;
    padding-right: .5rem;
`;
const StepSection = styled.div`
    flex-direction: column;
    gap: .25rem;
    display: flex;
`;
function CreateWorkout(props) {
    const navigate = useNavigate();
    const [exercises, setExerices] = useState([]);
    useEffect(() => {
        setExerices(props.store.Exercises.exercises);
    }, [props.store.Exercises.exercises])

    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [timeRelax, setTimeRelax] = useState(0);
    const [steps, setSteps] = useState([]);
    // step
    const [stepName, setStepName] = useState('');
    const [selectedExercise, setSelectedExercise] = useState(null);
    const [exerciseId, setExerciseId] = useState('')
    const [stepDescription, setStepDescription] = useState('');
    const [stepIndex, setStepIndex] = useState(null);
    const [timeBetweenReps, setTimeBetweenReps] = useState(0);
    const [countSets, setCountSets] = useState(0);
    const [countReps, setCountReps] = useState(0);

    const [fileCover, setFileCover] = useState(null);
    const [previewCover, setPreviewCover] = useState(null);
    useEffect(() => {
        if (fileCover !== null) {
            const obj = URL.createObjectURL(fileCover);
            setPreviewCover(obj);

            return () => URL.revokeObjectURL(obj)
        }
        else {
            setPreviewCover(null);
        }
    }, [fileCover])

    function setImagePreview(event, set) {
        if (event.target.files.length > 0 && event.target.files[0] instanceof File) {
            if (event.target.files[0].type.includes('image')) {
                set(event.target.files[0]);
                event.target.value = '';
            }
            else {
                toast.error('Выбрано не изображение')
            }
        }
    }

    function addStep() {
        if (!checkStep()) {
            return;
        }

        const data = {
            name: exercises.find(e => e.id === exerciseId)?.name || stepName,
            description: stepDescription,
            exercise_id: exerciseId,
            timeBetweenReps: timeBetweenReps,
            countSets: countSets,
            countReps: countReps
        }

        let temp = [...steps];
        temp.push(data);
        setSteps(temp);

        setStepName('');
        setStepDescription('');
        setStepIndex(null);
        setSelectedExercise(null);
        setExerciseId('');
        setTimeBetweenReps(0);
        setCountReps(0);
        setCountSets(0);
    }

    function updateStep() {
        if (!checkStep()) {
            return;
        }

        const data = {
            name: exercises.find(e => e.id === exerciseId)?.name || stepName,
            description: stepDescription,
            exercise_id: exerciseId,
            timeBetweenReps: timeBetweenReps,
            countSets: countSets,
            countReps: countReps
        }

        steps[stepIndex] = data;
        setSteps(steps);

        setStepDescription('');
        setStepIndex(null);
    }

    function setStep(index, step) {
        if (step === null) {
            return;
        }

        setStepDescription(step.description);
        setStepIndex(index);

        setStepName(step.name);
        setSelectedExercise(null);
        setExerciseId(step.exercise_id);
        setTimeBetweenReps(step.timeBetweenReps);
        setCountReps(step.countSets);
        setCountSets(step.countReps);
    }

    function deleteStep(index) {
        let currentSteps = [...steps];
        currentSteps.splice(index, 1);
        setSteps(currentSteps);
    }

    function changeStepOrder(prevIndex, nextIndex) {
        let t = [...steps];
        [t[nextIndex], t[prevIndex] ] = [ t[prevIndex], t[nextIndex]]
        setSteps(t);
    }

    function clearStep() {
        setStepDescription('');
        setStepIndex(null);
        setStepName('');
        setStepDescription('');
        setStepIndex(null);
        setSelectedExercise(null);
        setExerciseId('');
        setTimeBetweenReps(0);
        setCountReps(0);
        setCountSets(0);
    }

    function checkExercise() {
        if (name === '') {
            toast.error('Не заполнено название');
            return false;
        }

        return true;
    }

    function checkStep() {
        if (exerciseId === '' && stepName === '') {
            toast.error('Нужно выбрать упражнение или ввести название')
            return false;
        }

        if (timeBetweenReps < 0) {
            toast.error('Время между подходами не может быть отрицательным')
            return false;
        }

        if (countReps <= 0) {
            toast.error('Количество повторений должно быть больше 0')
            return false;
        }

        if (countSets <= 0) {
            toast.error('Количество подходов должно быть больше 0')
            return false;
        }

        return true;
    }

    async function create() {
        if (!checkExercise()) {
            return;
        }

        let coverId = null;
        let data = new FormData();
        if (fileCover !== null) {
            coverId = v4();
            data.append('file',fileCover);
            data.append('id', coverId);

            try {
                const response = await props.store.Images.upload(data);
                if (!response.data.status) {
                    toast.error(response.data.message);
                    return;
                }
            }
            catch (error) {
                toast.error(error?.response?.data?.message || error.message);
                return;
            };
        }

        data = new FormData();
        data.append('name', name);
        data.append('description', description);
        data.append('image_id', coverId);
        data.append('time_between_exercise', timeRelax);

        try {
            const response = await props.store.Workouts.create(data);
            if (!response.data.status) {
                toast.error(response.data.message);
                return;
            }

            const workoutId = response.data.data.id;
            let indx = 0;
            for(let step of steps) {
                data = new FormData();
                data.append('workout_id', workoutId);
                data.append('description', step.description);
                data.append('name', step.name);
                data.append('time_between_exercise', step.timeBetweenReps);
                data.append('order_number', indx);
                data.append('exercise_id', step.exercise_id);
                data.append('repeat_count', step.countSets);
                data.append('number_in_repeat', step.countReps);

                const stepResponse = await props.store.WorkoutItems.create(data);
                if (!stepResponse.data.status) {
                    toast.error(stepResponse.data.message);
                    return;
                }

                indx++;
            }
        }
        catch (error) {
            toast.error(error?.response?.data?.message || error.message);
            return;
        }

        props.store.Workouts.loadingData();
        props.store.Images.loadingData();
        navigate('/workouts');
    }

    return <Component>
        <h2>Основная информация</h2>
        <span>Давай заполним основую информацию</span>
        <Input type='text' value={name} label='Название' onChange={(event => setName(event.target.value))}/>
        <Textarea type='text' value={description} rows='3' label='Описание' onChange={(event => setDescription(event.target.value))}/>
        <Input type='number' value={timeRelax} label='Отдых между тренировками' onChange={(event => setTimeRelax(event.target.value))}/>
        <span>Также можно добавить обложку</span>
        { fileCover !== null && <Image centered data={fileCover}/> }
        <Centered>
            <InputContainer>
                <ImageInput type='file' id='cover' onChange={(event) => {setImagePreview(event, setFileCover)}}/>
                <label for='cover'>Выбрать обложку</label>
            </InputContainer>
            { previewCover !== null && <Button type='delete' onClick={() => setFileCover(null)}>Удалить обложку</Button> }
        </Centered>
        <h2>Добавить упражнение</h2>
        <span>Здесь ты можешь добавить шаги или изображения</span>
        <select value={exerciseId} onChange={(event) => setExerciseId(event.target.value)}>
            <option key={v4()} value=''>Выберите упражнение</option>
        {
            exercises.slice().map(e => <option key={e.id} value={e.id}>{e.name}</option>)
        }
        </select>
        <span>или</span>
            <Input type='text' value={stepName} label='Название' onChange={(event => setStepName(event.target.value))}/>
        <Textarea type='text' value={stepDescription} rows='5' label='Описание действий' onChange={(event => setStepDescription(event.target.value))}/>
        
        <Centered>
        <Input type='number' value={timeBetweenReps} label='Время между подходами' onChange={(event => setTimeBetweenReps(event.target.value))}/>
        <Input type='number' value={countSets} label='Количество подходов' onChange={(event => setCountSets(event.target.value))}/>
        <Input type='number' value={countReps} label='Количество повторений' onChange={(event => setCountReps(event.target.value))}/>
        </Centered>
        <Centered>
            <Button onClick={() => stepIndex !== null ? updateStep() : addStep()}>{stepIndex !== null ? 'Изменить' : 'Добавить'} шаг</Button>
            { stepIndex !== null && <Button onClick={() => clearStep()}>Отмена</Button> }
        </Centered>
        {
            steps.length > 0 && <>
                <h2>Упражнения тренировки</h2>
                <StepList>
                {
                    steps.map((step, index) => <StepSection key={'step_'+index}>
                        <div style={{display: 'flex'}}>
                            <StepTitle>Упражнение #{index+1} {step.exercise?.name || step.name}</StepTitle>
                            <span>🧘{step.timeBetweenReps} секунд {step.countSets}x{step.countReps}</span>
                            <div style={{flex: 1}}/>
                            { index !== 0 && <EmojiButton onClick={() => changeStepOrder(index, index - 1)}>⬆️</EmojiButton> }
                            { index + 1 !== steps.length && <EmojiButton onClick={() => changeStepOrder(index, index + 1)}>⬇️</EmojiButton> }
                            <EmojiButton onClick={() => setStep(index, step)}>✏️</EmojiButton>
                            <EmojiButton onClick={() => deleteStep(index)}>❌</EmojiButton>

                        </div>
                        <span style={{fontSize: '0.9rem', fontStyle: 'italic'}}>{step.description}</span>
                        
                    </StepSection>)
                }
                </StepList>
            </>
        }
        <Centered>
            <Button onClick={() => create()}>Создать</Button>
        </Centered>
    </Component>;
}

export default  inject("store")(CreateWorkout);