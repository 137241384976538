import { toJS } from 'mobx';
import { useState } from 'react';
import styled from 'styled-components';
import { Colors } from '../constants';

const Component = styled.div`
    position: relative;
    justify-content: center;
    align-items: center;
    display: flex;
    width: 36px;
    height: 36px;
    border-radius: 25%;
    background-color: white;
    color: ${Colors.secondary};
    font-weight: 500;
    font-size: .9rem;
    cursor: pointer;
    border: 1px solid ${Colors.light_green};
`;
const UserMenu = styled.div`
    position: absolute;
    top: calc(36px + 1rem);
    right: 0;
    background-color: white;
    color: gray;
    font-weight: 400;
    border: 1px solid lightgray;
    border-radius: .25rem;
    padding: .5rem;
    z-index: 1200;
`;
function Avatar(props) {
    const [showMenu, setShowMenu] = useState(false);
    const userData = toJS(props.store.User.user);

    function showingMenu() {
        if (showMenu) {
            setTimeout(function () {setShowMenu(false)}, 5000);
        }

        setShowMenu(!showMenu)
    }

    return <Component>
        <span onClick={() => showingMenu()}>{userData !== undefined && userData.first_name.substr(0,1)+userData.last_name.substr(0,1)}</span>
        {
            showMenu && <UserMenu>
            <span onClick={() => props.store.User.logout()}>Выйти</span>
        </UserMenu>
        }
        
    </Component>
}

export default Avatar;