import { makeObservable, observable, action, computed } from "mobx";
import axios from 'axios';
import { getToken } from '../utils';
import { Config } from '../constants';
import { toast } from 'react-toastify';

const NODE_ENV = process.env.NODE_ENV || 'production';
class Diaries {
    diaries = []

    constructor(title) { 
        makeObservable(this, {
            diaries: observable,
            loadingData: action,
            create: action,
            delete: action,
            setDiaries: action,
            get: action
        })

        this.loadingData = this.loadingData.bind(this);
        this.create = this.create.bind(this);
        this.delete = this.delete.bind(this);
        this.setDiaries = this.setDiaries.bind(this);

        axios.defaults.headers.common['x-user-language'] = 'ru';
        axios.defaults.headers.common['x-app-name'] = 'fitness';
        axios.defaults.headers.common['x-access-token'] = getToken();
        this.loadingData();
    }

    async loadingData() {
        let diariesResponse, itemsResponse, photosResponse;
        try {
            diariesResponse = await axios.get(Config.api.host.fitness[NODE_ENV]+Config.api.diaries.get_all);
            itemsResponse = await axios.get(Config.api.host.fitness[NODE_ENV]+Config.api.diary_items.get_all);
            photosResponse = await axios.get(Config.api.host.fitness[NODE_ENV]+Config.api.diary_photos.get_all);
        }
        catch (error) {
            toast.error(error?.response?.data?.message || error.message);
            return;
        }

        if (!diariesResponse.data.status || !itemsResponse.data.status || !photosResponse.data.status) {
            toast.error(diariesResponse.data.message);
            return;
        }

        let diaries = [];
        let steps;
        let photos;
        diariesResponse.data.data.forEach(exercise => {
            steps = [];
            photos = [];
            
            itemsResponse.data.data
            .filter(step => step.diaryId === exercise.id)
            .forEach(step => {
                steps.push(step);
            })

            photosResponse.data.data
            .filter(step => step.diaryId === exercise.id)
            .forEach(step => {
                photos.push(step);
            })

            exercise.steps = steps;
            exercise.photos = photos;
            diaries.push(exercise);
        });

        this.setDiaries(diaries);
    }

    setDiaries(data) {
        this.diaries = data;
    }

    get(id) {
        return this.diaries.find(ex => ex.id === id);
    }

    create(data) {
        return axios.post(Config.api.host.fitness[NODE_ENV]+Config.api.diaries.create, data);
    }

    update(data) {
        return axios.put(Config.api.host.fitness[NODE_ENV]+Config.api.diaries.update, data);
    }

    delete(id) {
        return axios.delete(Config.api.host.fitness[NODE_ENV]+Config.api.diaries.delete+id);
    }
}

export default new Diaries();