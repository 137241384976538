import { makeObservable, observable, action } from "mobx"

class Global {
    modalType = 'none';
    modalData = null;
    showDialog = false;
    dialogProps = null;

    constructor(title) {
        makeObservable(this, {
            modalType: observable,
            showDialog: observable,
            dialogProps: observable,
            setModal: action,
            newDialog: action,
            hideDialog: action
        })
    }

    setModal(type, data) {
        if (type === undefined) {
            this.modalType = 'none';
            this.modalData = null;
            return;
        }

        this.modalType = type;
        this.modalData = data;
    }

    newDialog(props) {
        this.showDialog = true;
        this.dialogProps = Object.create(props);
    }

    hideDialog() {
        this.showDialog = false;
        this.dialogProps = null;
    }
}

export default new Global();