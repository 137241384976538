// libs
import styled from "styled-components";
import { observer } from "mobx-react";
import Usrs from '../../store/user';
import { dataStore } from "../../store/data";
// components
import HistoryWorkout from "../../components/history_workout";
import List from '../../components/shared/list';
import Workout from "../../components/workout";
import Weight from "../../components/weight";

const Component = styled.div`
    padding: 1rem;
    overflow: hidden;
    width: 100%;
`;
const WorkoutList = styled.div`
    flex-direction: row;
    gap: 1rem;
    display: flex;
    width: 100%;
    overflow: auto;
`;
const HistorySection = styled.div`
    flex-direction: row;
    justify-content: start;
    gap: 2rem;
    display: flex;
`;

function Main(props) {
    const { workouts, diaries, weights } = dataStore;
    const { userName} = Usrs;

    return <Component>
        <h1>Привет, {userName}!👋</h1>
        <h2>Твои тренировки</h2>
        <WorkoutList>
        {
            workouts.map(workout => <Workout data={workout} />
            )
        }
        </WorkoutList>
        <HistorySection>
            <Section title='История тренировок'>
            {
                diaries.map(train => <HistoryWorkout data={train}/>)
            }
            </Section>
            <Section title='История веса'>
            {
                weights.map(weight => <Weight data={weight}/>)
            }
            </Section>
        </HistorySection>
    </Component>
}

const Section = ({title, children}) => {
    return <div>
        <h2>{title}</h2>
        <List>
        { children }
        </List>
    </div>
}

export default observer(Main);