export const Colors = {
    green: '#AAE9C4',
    light_green: 'rgb(231 253 239 / 30%)',//'#E7FDEF',
    red: '#DD4A48',
    peach: '#ECB390',
    primary: '#7BD5F5',
    secondary: '#787FF6'
}

export const Paths = {
    main: '/',
    workout: '/workout/',
    workouts: '/workouts',
    exercise: '/exercise/',
    exercises: '/exercises',
    diaries: '/diaries',
    diary: '/diary/',
    createExercise: '/create-exercise',
    createWorkout: '/create-workout',
    edit_exercise: '/edit-exercise',
    training: '/training/'
}

export const BREAKPOINTS = {
    mobileS: '320px',
    mobileM: '375px',
    mobileL: '425px',
    tablet: '768px',
    laptopS: '1024px',
    laptopM: '1200px',
    laptopL: '1440px',
    desktop: '2560px'
};
export const Device = {
    mobileS: `(min-width: ${BREAKPOINTS.mobileS})`,
    mobileM: `(min-width: ${BREAKPOINTS.mobileM})`,
    mobileL: `(min-width: ${BREAKPOINTS.mobileL})`,
    tablet: `(min-width: ${BREAKPOINTS.tablet})`,
    laptopS: `(min-width: ${BREAKPOINTS.laptopS})`,
    laptopM: `(min-width: ${BREAKPOINTS.laptopM})`,
    laptopL: `(min-width: ${BREAKPOINTS.laptopL})`,
    desktop: `(min-width: ${BREAKPOINTS.desktop})`
  };

export const Modals = {
    create_exercise: {
        name: 'create_exercise',
        label: 'Добавить упражнение'
    },
    create_workout: {
        name: 'create_workout',
        label: 'Добавить тренировку'
    },
    create_menu: {
        name: 'create_menu',
        label: 'Добавить меню'
    },
    edit_exercise: {
        name: 'edit_exercise',
        label: 'Редактирование упражнения'
    },
    edit_recipe: {
        name: 'edit_recipe',
        label: 'Редактирование тренировки'
    },
    edit_menu: {
        name: 'edit_menu',
        label: 'Редактирование меню'
    },
    show_workout: {
        name: 'show_workout',
        label: 'Посмотреть тренировку'
    },
    dialog: {
        name: 'dialog',
        label: ''
    },
    create_weight: {
        name: 'create_weight',
        label: 'Добавить вес'
    },
    create_diary: {
        name: 'create_diary',
        label: 'Добавить'
    }
}

export const Config = {
    IMAGES: 'images',
    WORKOUTS: 'workouts',
    WORKOUTITEMS: 'workout_items',
    EXERCISES: 'exercises',
    EXERCISEITEMS: 'exercise_items',
    DIARIES: 'diaries',
    DIARYITEMS: 'diary_items',
    DIARYPHOTOS: 'diary_photos',
    WEIGHTS: 'weights',
    env: process.env.NODE_ENV || 'production',
    api: {
        host: {
            common: {
                development: 'http://localhost:8100/',
                production: 'https://tsvetkov.one:8100/'
            },
            fitness: {
                development: 'http://localhost:8140/',
                production: 'https://fitness.tsvetkov.one:8140/'
            }
        },
        common: {
            sign_up: "api/auth/signup",
            sign_in: "api/auth/signin",
            get: "api/user",
            update: "api/user",
            change_profile_image: "api/changeprofileimage"
        },
        images: {
            create: 'api/image',
            get_all: 'api/images',
            update: 'api/image',
            delete: 'api/image/',
            upload: 'api/im'
        },
        workouts: {
            create: 'api/workout',
            get_all: 'api/workouts',
            update: 'api/workout',
            delete: 'api/workout/'
        },
        workout_items: {
            create: 'api/workoutItem',
            get_all: 'api/workoutItems',
            update: 'api/workoutItem',
            delete: 'api/workoutItem/'
        },
        exercises: {
            create: 'api/exercise',
            get_all: 'api/exercises',
            update: 'api/exercise',
            delete: 'api/exercise/'
        },
        exercise_items: {
            create: 'api/exerciseItem',
            get_all: 'api/exerciseItems',
            update: 'api/exerciseItem',
            delete: 'api/exerciseItem/'
        },
        main: {
            sign_up: "api/auth/signup",
            sign_in: "api/auth/signin",
            get: "api/user",
            update: "api/user",
            change_profile_image: "api/changeprofileimage"
        },
        diaries: {
            create: 'api/diary',
            get_all: 'api/diaries',
            update: 'api/diary',
            delete: 'api/diary/'
        },
        diary_items: {
            create: 'api/diaryItem',
            get_all: 'api/diaryItems',
            update: 'api/diaryItem',
            delete: 'api/diaryItem/'
        },
        diary_photos: {
            create: 'api/diaryPhoto',
            get_all: 'api/diaryPhotos',
            update: 'api/diaryPhoto',
            delete: 'api/diaryPhoto/'
        },
        weights: {
            create: 'api/weight',
            get_all: 'api/weights',
            update: 'api/weight',
            delete: 'api/weight/'
        },
    }
}