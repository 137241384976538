import { makeObservable, observable, action } from "mobx";
import axios from 'axios';
import { getToken } from '../utils';
import { Config } from '../constants';
import { toast } from 'react-toastify';

const NODE_ENV = process.env.NODE_ENV || 'production';
class Workouts {
    workouts = []
    id = Math.random();
    constructor(title) { 
        this.id = Math.random();
        makeObservable(this, {
            workouts: observable,
            loadingData: action,
            create: action,
            update: action,
            delete: action,
            setWorkouts: action,
            get: action
        })

        this.loadingData = this.loadingData.bind(this);
        this.create = this.create.bind(this);
        this.update = this.update.bind(this);
        this.delete = this.delete.bind(this);

        axios.defaults.headers.common['x-user-language'] = 'ru';
        axios.defaults.headers.common['x-app-name'] = 'fitness';
        axios.defaults.headers.common['x-access-token'] = getToken();
        this.loadingData();
    }

    async loadingData() {
        console.debug('class id:', this.id);
        /*
        axios.get(Config.api.host.fitness[NODE_ENV]+Config.api.workouts.get_all)
        .then(result => {
            if (result.data.status) {
                this.workouts = result.data.data;
            }
            else {
                toast.error(result.data.message)
            }
        })
        .catch(error => {
            toast.error(error)
        })
        */
        let workoutResponse, stepsResponse;
        try {
            workoutResponse = await axios.get(Config.api.host.fitness[NODE_ENV]+Config.api.workouts.get_all);
            stepsResponse = await axios.get(Config.api.host.fitness[NODE_ENV]+Config.api.workout_items.get_all);
        }
        catch (error) {
            toast.error(error?.response?.data?.message || error.message);
            return;
        }

        if (!workoutResponse.data.status || !stepsResponse.data.status) {
            toast.error(workoutResponse.data.message);
            return;
        }

        let workouts = [];
        let steps;
        workoutResponse.data.data.forEach(workout => {
            steps = [];
            
            stepsResponse.data.data
            .filter(step => step.workout_id === workout.id)
            .forEach(step => {
                steps.push(step);
            })

            workout.steps = steps;
            workouts.push(workout);
        });

        this.setWorkouts(workouts);
    }

    setWorkouts(list) {
        this.workouts = [...list];
    }

    get(id) {
        return this.workouts.find(w => w.id === id);
    }

    create(data) {
        return axios.post(Config.api.host.fitness[NODE_ENV]+Config.api.workouts.create, data);
    }

    update(data) {
        return axios.put(Config.api.host.fitness[NODE_ENV]+Config.api.workouts.update, data);
    }

    delete(id) {
        return axios.delete(Config.api.host.fitness[NODE_ENV]+Config.api.workouts.delete+id);
    }
}

export default new Workouts();