// libs
import { observer } from "mobx-react";
import styled from "styled-components";
import { useNavigate, useParams } from 'react-router-dom';
// components
import Workout from "../../components/workout";
// others
import { Colors, Device, Paths } from "../../constants";
import { dataStore } from '../../store/data';

const Component = styled.div`
    padding: 3rem 2rem;
    overflow: auto;

    @media ${Device.mobileS} {
        padding: 2rem 1rem;
    }

    @media ${Device.tablet} {
        padding: 2rem 1rem;
    }

    @media ${Device.laptopS} {
        padding: 2rem 1rem;
    }

    @media ${Device.laptopM} {
        padding: 2rem 1.5rem;
    }

    @media ${Device.laptopL} {
        padding: 3rem 2rem;
    }
`;
const WorkoutList = styled.div`
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: stretch;
    flex-direction: row;
    gap: 2rem;
    display: flex;
    width: 100%;
    align-content: space-around;

    @media ${Device.mobileS} {
        gap: 1rem;
    }

    @media ${Device.tablet} {
        gap: 1rem;
    }

    @media ${Device.laptopS} {
        gap: 1rem;
    }

    @media ${Device.laptopM} {
        gap: 1rem;
    }

    @media ${Device.laptopL} {
        gap: 2rem;
    }
`;
const ButtonCreateWorkout = styled.div`
    justify-content: center;
    align-items: center;
    transition: color .15s ease-in, border .15s ease-in;
    display: flex;
    border-radius: .5rem;
    border: 1px solid lightgray;
    width: 350px;//calc((1920px - 4rem) / 5);
    height: 200px;
    background-color: white;
    //font-family: 'Amatic SC', cursive;
    font-size: 2rem;
    user-select: none;
    cursor: pointer;

    &:hover {
        color: ${Colors.secondary};
        border: 1px solid ${Colors.secondary};
    }

    

    @media ${Device.tablet} {
        //max-width: calc((768px - 4rem) / 3);
        //height: 200px;
        font-size: 1.5rem;
    }

    @media ${Device.laptopS} {
        //max-width: calc((1000px - 4rem) / 4);
        //height: 200px;
        font-size: 1.5rem;
    }

    @media ${Device.laptopM} {
        //max-width: calc((1200px - 2rem) / 5);
        //height: 200px;
        font-size: 1.5rem;
    }

    @media ${Device.laptopL} {
        //max-width: calc((1920px - 4rem) / 5);
        //height: 250px;
        font-size: 1.5rem;
    }
`;


function WorkoutsPage(props) {
    const { workouts } = dataStore;
    const navigate = useNavigate();

    return <Component>
        <WorkoutList>
            <ButtonCreateWorkout onClick={() => navigate(Paths.createWorkout)}>
                <span>Добавить тренировку</span>
            </ButtonCreateWorkout>
            {
                workouts.map(recipe => <Workout data={recipe} />)
            }
        </WorkoutList>
    </Component>
}

export default observer(WorkoutsPage);