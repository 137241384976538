import styled from "styled-components";
import Input from "../components/input_material";
import Textarea from "../components/textarea_material";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { inject } from 'mobx-react';
import { Colors, Device } from "../constants";
import Button from '../components/button';
import Image from "../components/image_preview";
import { v4 } from 'uuid';

const Component = styled.div`
    flex-direction: column;
    gap: 1rem;
    display: flex;
    width: 800px;
    margin: 1rem auto;
    overflow: auto;
    background-color: white;
    padding: 1rem;
    border-radius: .25rem;
`;
const InputContainer = styled.button`
    position: relative;
    //margin: 0 auto;
    border: 1px solid ${Colors.primary};
    border-radius: .25rem;
    width: fit-content;
    padding: .5rem;
    background: ${Colors.primary};
    color: white;
    cursor: pointer;
`;
const ImageInput = styled.input`
    width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
`;
const Group = styled.div`
    flex-direction: column;
    gap: 1rem;
    display: flex;
    border: 1px solid lightgrey;
    border-radius: .25rem;
    padding: 1rem;

`;
const ImagePreview = styled.img`
    border-radius: .25rem;
    width: 400px;
    height: 200px;
    margin: ${props => props.centered !== undefined ? '0 auto' : '0'};
    object-fit: cover;

    @media ${Device.mobileS} {
        width: 100%;
        height: 200px;
    }

    @media ${Device.mobileM} {
        width: 100%;
        height: 200px;
    }

    @media ${Device.mobileL} {
        width: 100%;
        height: 200px;
    }

    @media ${Device.tablet} {
        width: 300px;
        height: 150px;
    }

    @media ${Device.laptopS} {
        width: 300px;
        height: 150px;
    }

    @media ${Device.laptopM} {
        width: 400px;
        height: 200px;
    }

    @media ${Device.laptopL} {
        width: 400px;
        height: 200px;
    }
`;
const Centered = styled.div`
    justify-content: center;
    align-items: center;
    gap: 1rem;
    display: flex;
`;
const EmojiButton = styled.span`
    cursor: pointer;
`;
const StepList = styled.div`
    flex-direction: column;
    gap: 1rem;
    display: flex;
`;
const StepTitle = styled.span`
    font-size: larger;
    padding-right: .5rem;
`;
const StepSection = styled.div`
    position: relative;
    flex-direction: column;
    gap: .25rem;
    display: flex;
`;
const WillDelete = styled.div`
    content: "Будет удален";
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,0.5);
    color: #FFF;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    gap: 1rem;
    align-items: center;
`;
function EditExercise(props) {
    const { id } = useParams();
    const [init, setInit] = useState(false);
    const [source, setSource] = useState(null);
    const [stepChanged, setStepChanged] = useState(false);
    useEffect(() => {
        if (init) return;

        const exercise = props.store.Exercises.get(id);
        console.log(exercise);
        setName(exercise.name);
        setDescription(exercise.description);
        exercise.image = exercise.image_id ? props.store.Images.mapImages[exercise.image_id] : null;
        setFileCover(exercise.image?.path);
        exercise.steps.forEach(e => {
            e.image = e.image_id ? props.store.Images.mapImages[e.image_id] : null;
        })
        setSteps(exercise.steps);
        setSource(exercise);
        setInit(true);
    }, [props.store.Exercises.get(id)])

    const navigate = useNavigate();
    const [steps, setSteps] = useState([]);
    // exercise
    const [name, setName] = useState('')
    const [description, setDescription] = useState('');
    // step
    const [stepDescription, setStepDescription] = useState('')
    const [stepIndex, setStepIndex] = useState(null);

    const [fileCover, setFileCover] = useState(null);
    const [previewCover, setPreviewCover] = useState(null);
    useEffect(() => {
        if (fileCover !== null && fileCover !== undefined) {
            if (fileCover instanceof File) {
            const obj = URL.createObjectURL(fileCover);
            setPreviewCover(obj);

            return () => URL.revokeObjectURL(obj)
            }

            if (fileCover instanceof Object) {
                setPreviewCover(fileCover.path);
            }
        }
        else {
            setPreviewCover(null);
        }
    }, [fileCover])

    const [fileTempStepCover, setFileTempStepCover] = useState(null);
    const [previewTempStepCover, setPreviewTempStepCover] = useState(null);
    useEffect(() => {
        if (fileTempStepCover !== null && fileTempStepCover !== undefined) {
            if (fileTempStepCover instanceof File) {
                const obj = URL.createObjectURL(fileTempStepCover);
                setPreviewTempStepCover(obj);

                return () => URL.revokeObjectURL(obj)
            }
    
            if (fileTempStepCover instanceof Object) {
                setPreviewCover(fileTempStepCover.path);
            }
        }
        else {
            setPreviewTempStepCover(null);
        }
    }, [fileTempStepCover])

    function setImagePreview(event, set) {
        if (event.target.files.length > 0 && event.target.files[0] instanceof File) {
            if (event.target.files[0].type.includes('image')) {
                set(event.target.files[0]);
                event.target.value = '';
            }
            else {
                toast.error('Выбрано не изображение')
            }
        }
    }

    function addStep() {
        if (!checkStep()) {
            return;
        }

        setStepChanged(true);

        const data = {
            description: stepDescription,
            image: fileTempStepCover,
            added: true
        }

        steps.push(data);
        setSteps(steps);

        setStepDescription('');
        setStepIndex(null);
        setFileTempStepCover(null);
    }

    function updateStep() {
        if (!checkStep()) {
            return;
        }

        setStepChanged(true);

        steps[stepIndex] = {...steps[stepIndex], description: stepDescription,
            image: fileTempStepCover,
            updated: true};
        setSteps(steps);

        setStepDescription('');
        setStepIndex(null);
        setFileTempStepCover(null);
    }

    function setStep(index, step) {
        if (step === null) {
            return;
        }

        setStepDescription(step.description);
        setFileTempStepCover(step.image?.path || step.image);
        setStepIndex(index);
    }

    function deleteStep(index) {
        setStepChanged(true);

        let currentSteps = [...steps];
        if (currentSteps[index].id) {
            currentSteps[index].deleted = true;    
        }
        else {
            currentSteps.splice(index, 1);
        }
        setSteps(currentSteps);
    }

    function changeStepOrder(prevIndex, nextIndex) {
        setStepChanged(true);

        let t = [...steps];
        t[nextIndex].updated = true;
        t[prevIndex].updated = true;
        [t[nextIndex], t[prevIndex] ] = [ t[prevIndex], t[nextIndex]]
        setSteps(t);
    }

    function clearStep() {
        setStepDescription('');
        setStepIndex(null);
        setFileTempStepCover(null);
    }

    function checkExercise() {
        if (name === '') {
            toast.error('Не заполнено название');
            return false;
        }

        return true;
    }

    function checkStep() {
        if (fileTempStepCover === null && stepDescription === '') {
            toast.error('Нужно выбрать изображение или заполнить описание шага')
            return false;
        }

        return true;
    }

    function cancelDelete(index) {
        let currentSteps = [...steps];
        delete currentSteps[index].deleted; 
        setSteps(currentSteps);
    }

    async function update() {
        if (!checkExercise()) {
            return;
        }

        let coverId = null;
        let data = new FormData();
        let coverChanged = false;
        if (fileCover !== null && fileCover instanceof File) {
            coverId = v4();
            data.append('file',fileCover);
            data.append('id', coverId);

            try {
                const response = await props.store.Images.upload(data);
                if (!response.data.status) {
                    toast.error(response.data.message);
                    return;
                }
            }
            catch (error) {
                toast.error(error?.response?.data?.message || error.message);
                return;
            };

            coverChanged = true;
        }
        
        if (description !== source.description || name !== source.name || coverChanged || stepChanged) {
            data = new FormData();
            data.append('id', id);
            data.append('name', name);
            data.append('description', description);
            if (coverChanged) {
                data.append('image_id', coverId);    
            }
            else {
                data.append('image_id', source.image_id);  
            }
            
            
            try {
                const response = await props.store.Exercises.update(data);
                if (!response.data.status) {
                    toast.error(response.data.message);
                    return;
                }

                let indx = 0;
                for(let step of steps) {
                    if (step.updated) {
                        coverId = null;
                        if (step.image !== null && step.image instanceof File) {
                            data = new FormData();
                            coverId = v4();
                            data.append('file',step.image);
                            data.append('id', coverId);

                            const imageResponse = await props.store.Images.upload(data);
                            if (!imageResponse.data.status) {
                                toast.error(imageResponse.data.message);
                                return;
                            }
                        }

                        data = new FormData();
                        data.append('id', step.id);
                        data.append('description', description);
                        data.append('image_id', coverId);
                        data.append('order_number', indx);
                        data.append('exercise_id', id);

                        const stepResponse = await props.store.ExerciseItems.update(data);
                        if (!stepResponse.data.status) {
                            toast.error(stepResponse.data.message);
                            return;
                        }
                    }

                    if (step.added) {
                        coverId = null;
                        if (step.image !== null) {
                            data = new FormData();
                            coverId = v4();
                            data.append('file',step.image);
                            data.append('id', coverId);

                            const imageResponse = await props.store.Images.upload(data);
                            if (!imageResponse.data.status) {
                                toast.error(imageResponse.data.message);
                                return;
                            }
                        }

                        data = new FormData();
                        data.append('description', description);
                        data.append('image_id', coverId);
                        data.append('order_number', indx);
                        data.append('exercise_id', id);

                        const stepResponse = await props.store.ExerciseItems.create(data);
                        if (!stepResponse.data.status) {
                            toast.error(stepResponse.data.message);
                            return;
                        }
                    }

                    if (step.deleted) {
                        const stepResponse = await props.store.ExerciseItems.delete(step.id);
                        if (!stepResponse.data.status) {
                            toast.error(stepResponse.data.message);
                            return;
                        }
                    }

                    indx++;
                }
            }
            catch (error) {
                toast.error(error?.response?.data?.message || error.message);
                return;
            }
        }

        await props.store.Exercises.loadingData();
        await props.store.Images.loadingData();
        navigate('/exercises');
    }

    return <Component>
        <h2>Основная информация</h2>
        <Input type='text' value={name} label='Название' onChange={(event => setName(event.target.value))}/>
        <Textarea type='text' value={description} rows='3' label='Описание' onChange={(event => setDescription(event.target.value))}/>
        { fileCover !== null && <Image centered width='100%' height='auto' data={fileCover}/> }
        <Centered>
            <InputContainer>
                <ImageInput type='file' id='cover' onChange={(event) => {setImagePreview(event, setFileCover)}}/>
                <label for='cover'>Выбрать обложку</label>
            </InputContainer>
            { previewCover !== null && <Button type='delete' onClick={() => { setFileCover(null)}}>Удалить обложку</Button> }
        </Centered>
        <h2>Добавить шаг</h2>
                    { fileTempStepCover !== null && <Image  width='100%' height='auto' centered data={fileTempStepCover}/> }
                    <Centered>
                        <InputContainer>
                            <ImageInput type='file' id='cover1' onChange={(event) => setImagePreview(event, setFileTempStepCover)}/>
                            <label for='cover1'>Выбрать изображение</label>
                        </InputContainer>
                        { previewTempStepCover !== null && <Button type='delete' onClick={() => setFileTempStepCover(null)}>Удалить</Button> }
                    </Centered>
                <Textarea type='text' value={stepDescription} rows='5' label='Описание действий' onChange={(event => setStepDescription(event.target.value))}/>
                <Centered>
                    <Button onClick={() => stepIndex !== null ? updateStep() : addStep()}>{stepIndex !== null ? 'Изменить' : 'Добавить'} шаг</Button>
                    { stepIndex !== null && <Button onClick={() => clearStep()}>Отмена</Button> }
                </Centered>
                {
                    steps.length > 0 && <>
                        <h2>Шаги упражнения</h2>
                        <StepList>
                        {
                            steps.map((step, index) => <StepSection key={'step_'+index}>
                                <div>
                                    <StepTitle>Шаг #{index+1}</StepTitle>
                                    { index !== 0 && <EmojiButton onClick={() => changeStepOrder(index, index - 1)}>⬆️</EmojiButton> }
                                    { index + 1 !== steps.length && <EmojiButton onClick={() => changeStepOrder(index, index + 1)}>⬇️</EmojiButton> }
                                    <EmojiButton onClick={() => setStep(index, step)}>✏️</EmojiButton>
                                    <EmojiButton onClick={() => deleteStep(index)}>❌</EmojiButton>
                                </div>
                                { (step.image?.path || step.image) && <Image  width='100%' height='auto' data={step.image?.path || step.image}/>}
                                <span style={{fontSize: '0.9rem', fontStyle: 'italic'}}>{step.description}</span>
                                { step.deleted && <WillDelete><span>Будет удален</span><EmojiButton onClick={() => cancelDelete(index)}>Отменить</EmojiButton></WillDelete>}
                            </StepSection>)
                        }
                        </StepList>
                    </>
                }
                <Centered>
                    <Button onClick={() => update()}>Сохранить</Button>
                </Centered>
        
    </Component>;
}

export default  inject("store")(EditExercise);