export const getToken = function() {
    return localStorage.getItem('token') || '';
}

export function setToken(_token) {
    localStorage.setItem('token', _token);
}

export function removeToken() {
    localStorage.removeItem('token');
}

/**
 * Склонение существительных
 * 
 * Заполнение массива:
 * [cлово для одной единицы, слово для двух единиц, слово для пяти единиц]
 * 
 * @example declension(0,["файл", "файла", "файлов"]);//returns "файлов"
 * @example declension(1,["файл", "файла", "файлов"]);//returns "файл"
 * @example declension(5,["файл", "файла", "файлов"]);//returns "файла"
 * @param {number} value 
 * @param {Array} words 
 * @returns {string}
 */
export function declension(value, words){  
	value = Math.abs(value) % 100; 
	var num = value % 10;
	if(value > 10 && value < 20) return words[2]; 
	if(num > 1 && num < 5) return words[1];
	if(num == 1) return words[0]; 
	return words[2];
}