import styled from "styled-components";
import Button from "../button";

const Component = styled.div`
    flex-direction: column;
    gap: .5rem;
    display: flex;
    padding: 1rem;
`;
const Centered = styled.div`
    flex-direction: row;
    gap: 1rem;
    display: flex;
    justify-content: space-around;
`;

export default function Dialog(props) {
    return <Component>
        <span>{props.data.message}</span>
        <Centered>
            <span onClick={() => props.data.yesClick()}>Да</span>
            <span onClick={() => props.data.noClick()}>Нет</span>
        </Centered>
    </Component>
}