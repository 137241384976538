import { useParams, useNavigate } from "react-router-dom";
import styled, { css, keyframes} from "styled-components";
import { useState, useEffect, useRef } from "react";
import { inject, observer } from "mobx-react";
import { Colors, Config } from "../../constants";
import Welcome from "./welcome";
import Exercise from "./exercise";
import Relax from "./relax";
import { toast } from "react-toastify";
import { dataStore } from "../../store/data";
import Finish from "./finish";

const anim = keyframes`
    from {
        filter: blur(200px);
    }
    to {
        filter: blur(4px);
    }
`;
const animop = keyframes`
    0% {
        opacity: 0;
    }
    99% {
        opacity: 1;
    }
`;
const Component = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: center;
    position: relative;
    width: 100%;
    height: 100%;
    background: ${props => props.background !== null ? 'no-repeat url("'+props.background+'")' : ''};
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 1rem;
    
    ${props => props.background !== undefined && css`&::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 50%;
        height: 50%;
        transform: translate(-50%, -50%);
        background-color: rgba(255,255,255,.8);
        filter: blur(4px);
        animation: ${anim} .3s cubic-bezier(0.075, 0.82, 0.165, 1);
    }`}
    
`;
const MainInfo = styled.div`
    content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 50%;
        height: 50%;
        transform: translate(-50%, -50%);
        background-color: rgba(255,255,255,.8);
        filter: blur(4px);
`;
const Info = styled.div`
position: absolute;
    top: 50%;
        left: 50%;
        width: 50%;
        height: 50%;
        transform: translate(-50%, -50%);
        border: 1px solid ${Colors.primary};
        padding: 1rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
`;
const Button = styled.button`
    background-color: ${Colors.primary};
    color: white;
    padding: 1rem;
    //width: 150px;
    text-transform: uppercase;
    font-size: larger;
    border: 1px solid white;
    border-radius: .25rem   ;
`;
function Training(props) {
    const { id } = useParams();
    const [workout, setWorkout] = useState(null);
    const { workouts } = dataStore;
    const [currentDiaryId, setCurrentDiaryId] = useState(null);
    const [backgroundSrc, setBackgroundSrc] = useState(null);
    const [currentEx, setCurrentEx] = useState(null);
    const [steps, setSteps] = useState([]);
    const navigate = useNavigate();
    const [buttonName, setButtonName] = useState('Начать');
    const [isRelax, setIsRelax] = useState(false);
    /*
    useEffect(() => {
        const w = props.store.Workouts.workouts.find(ww => ww.id === id);
        if (w) {
            w.image = w.image_id ? props.store.Images.mapImages[w.image_id] : null;
            setBackgroundSrc(w.image?.path);
            w.steps.sort(function (a,b) {return a.order_number - b.order_number});
            w.steps.forEach(step => {
                if (step.exercise_id) {
                    step.exercise = props.store.Exercises.exercises.find(e => e.id === step.exercise_id);    
                    if (step.exercise !== undefined) {
                    step.exercise.image = step.exercise?.image_id ? props.store.Images.mapImages[step.exercise.image_id] : null;
                    }
                }
                else {
                    step.exercise = null;
                }
                step.doneReps = 0;
            });
            setSteps(w.steps);
            setWorkout(w);
        }
    }, [id])
    */
    useEffect(() => {
        let ww = workouts.find(w => w.id === id);
        if (ww !== undefined) {
            setWorkout(Object.create(ww));
            let sts = [...ww.steps];
            sts.forEach(s => s.doneReps = 0);
            setSteps(sts)
        }
    }, [id, workouts])

    useEffect(() => {
        if (currentEx === null) {
            setButtonName('Начать');
            return;
        }

        if (isRelax) {
            setButtonName('Продолжить');
            return;
        }

        if (currentEx !== null && !isRelax) {
            setButtonName('Выполнил');
            return;
        }

        
    }, [currentEx, isRelax])

    const [isStart, setStart] = useState(true);
    async function next(feel) {
        if (isFinish) {
            finish(feel);
            return;
        }

        if (backgroundSrc !== null) {
            setBackgroundSrc(null);
        }

        if (isStart) {
            try {
                const response = await dataStore.create(Config.DIARIES, {
                    workoutId: id
                });
                if (!response.data.status) {
                    toast.error(response.data.message);
                    return;
                }

                if (!window.confirm("Найдена незавершенная тренировка. Продолжить её?")) {
                    return;
                }

                setCurrentDiaryId(response.data.data.id);
            }
            catch (error) {
                toast.error(error?.response?.data?.message || error.message);
                return;
            };
            setStart(false);
        }
    
        let ss = [...steps];
        console.log('here 1')
        if (currentEx !== null && !isRelax) {
            let indx = ss.findIndex(s => s.id === currentEx.id);
            currentEx.doneReps += 1;
            ss[indx] = currentEx;
            setIsRelax(true);
            setSteps(ss);
            //start(500);
            setosttime(10);
            return;
        }

        let next = ss.find((s,ind) => s.doneReps < s.repeat_count);
        if (currentEx !== null) {
            try {
                const response = await dataStore.create(Config.DIARYITEMS, {
                    diaryId: currentDiaryId,
                    workoutItemId: currentEx.id,
                    feel: feel === undefined ? null : feel
                })
                if (!response.data.status) {
                    toast.error(response.data.message);
                    return;
                }
            }
            catch (error) {
                toast.error(error?.response?.data?.message || error.message);
                return;
            };
        }
        
        if (next === undefined) {
            setFinished(true);
            setIsRelax(false);
            return;
        }
        
        setIsRelax(false)
        setCurrentEx(next);

    }

    const [ctim, setct] = useState(null);
    const [osttime, setosttime] = useState(0);
    const [isFinish, setFinished] = useState(false);
    async function finish(feel) {
        try {
            const response = await dataStore.update(Config.DIARIES, {
                id: currentDiaryId,
                workoutId: id,
                feel: feel,
                isFinished: true
            });
            if (!response.data.status) {
                toast.error(response.data.message);
                return;
            }
        }
        catch (error) {
            toast.error(error?.response?.data?.message || error.message);
            return;
        };

        await dataStore.fetch();
        navigate('/');
    }

    return <Component >
        { !currentEx && workout && <Welcome data={workout} /> }
        { !isRelax && !isFinish && currentEx && <Exercise data={currentEx}/> }
        { isRelax && <Relax time={osttime} next={next}/> }
        {
            isFinish && <Finish id={currentDiaryId} workoutId={id} next={next}/>
        }
        { !isRelax && !isFinish && <Button onClick={() => next()}>{buttonName}</Button> }
    </Component>
}

export default observer(Training);