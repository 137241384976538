import styled from "styled-components";
import Input from "../../components/input_material";
import Textarea from "../../components/textarea_material";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { inject } from 'mobx-react';
import { Colors, Device } from "../../constants";
import Button from '../../components/button';
import Image from "../../components/image_preview";
import { v4 } from 'uuid';

const Component = styled.div`
    flex-direction: column;
    gap: 1rem;
    display: flex;
    width: 800px;
    margin: 1rem auto;
    overflow: auto;
    background-color: white;
    padding: 1rem;
    border-radius: .25rem;
`;
const InputContainer = styled.button`
    position: relative;
    //margin: 0 auto;
    border: 1px solid ${Colors.primary};
    border-radius: .25rem;
    width: fit-content;
    padding: .5rem;
    background: ${Colors.primary};
    color: white;
    cursor: pointer;
`;
const ImageInput = styled.input`
    width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
`;
const Group = styled.div`
    flex-direction: column;
    gap: 1rem;
    display: flex;
    border: 1px solid lightgrey;
    border-radius: .25rem;
    padding: 1rem;

`;
const ImagePreview = styled.img`
    border-radius: .25rem;
    width: 400px;
    height: 200px;
    margin: ${props => props.centered !== undefined ? '0 auto' : '0'};
    object-fit: cover;

    @media ${Device.mobileS} {
        width: 100%;
        height: 200px;
    }

    @media ${Device.mobileM} {
        width: 100%;
        height: 200px;
    }

    @media ${Device.mobileL} {
        width: 100%;
        height: 200px;
    }

    @media ${Device.tablet} {
        width: 300px;
        height: 150px;
    }

    @media ${Device.laptopS} {
        width: 300px;
        height: 150px;
    }

    @media ${Device.laptopM} {
        width: 400px;
        height: 200px;
    }

    @media ${Device.laptopL} {
        width: 400px;
        height: 200px;
    }
`;
const Centered = styled.div`
    justify-content: center;
    align-items: center;
    gap: 1rem;
    display: flex;
`;
const EmojiButton = styled.span`
    cursor: pointer;
`;
const StepList = styled.div`
    flex-direction: column;
    gap: 1rem;
    display: flex;
`;
const StepTitle = styled.span`
    font-size: larger;
    padding-right: .5rem;
`;
const StepSection = styled.div`
    flex-direction: column;
    gap: .25rem;
    display: flex;
`;
function CreateExercise(props) {
    const navigate = useNavigate();
    const [steps, setSteps] = useState([]);
    const [showStepSection, setShowStepSection] = useState(false);
    const [isStepEdit, setStepEdit] = useState(false)
    // exercise
    const [name, setName] = useState('')
    const [description, setDescription] = useState('');
    // step
    const [stepDescription, setStepDescription] = useState('')
    const [stepIndex, setStepIndex] = useState(null);

    const [fileCover, setFileCover] = useState(null);
    const [previewCover, setPreviewCover] = useState(null);
    useEffect(() => {
        if (fileCover !== null) {
            const obj = URL.createObjectURL(fileCover);
            setPreviewCover(obj);

            return () => URL.revokeObjectURL(obj)
        }
        else {
            setPreviewCover(null);
        }
    }, [fileCover])

    const [fileTempStepCover, setFileTempStepCover] = useState(null);
    const [previewTempStepCover, setPreviewTempStepCover] = useState(null);
    useEffect(() => {
        if (fileTempStepCover !== null) {
            const obj = URL.createObjectURL(fileTempStepCover);
            setPreviewTempStepCover(obj);

            return () => URL.revokeObjectURL(obj)
        }
        else {
            setPreviewTempStepCover(null);
        }
    }, [fileTempStepCover])

    function setImagePreview(event, set) {
        if (event.target.files.length > 0 && event.target.files[0] instanceof File) {
            if (event.target.files[0].type.includes('image')) {
                set(event.target.files[0]);
                event.target.value = '';
            }
            else {
                toast.error('Выбрано не изображение')
            }
        }
    }

    function addStep() {
        if (!checkStep()) {
            return;
        }

        const data = {
            description: stepDescription,
            image: fileTempStepCover
        }

        steps.push(data);
        setSteps(steps);

        setStepDescription('');
        setStepIndex(null);
        setFileTempStepCover(null);
    }

    function updateStep() {
        if (!checkStep()) {
            return;
        }

        const data = {
            description: stepDescription,
            image: fileTempStepCover,
        }

        steps[stepIndex] = data;
        setSteps(steps);

        setStepDescription('');
        setStepIndex(null);
        setFileTempStepCover(null);
    }

    function setStep(index, step) {
        if (step === null) {
            return;
        }

        setStepDescription(step.description);
        setFileTempStepCover(step.image);
        setStepIndex(index);
    }

    function deleteStep(index) {
        let currentSteps = [...steps];
        currentSteps.splice(index, 1);
        setSteps(currentSteps);
    }

    function changeStepOrder(prevIndex, nextIndex) {
        let t = [...steps];
        [t[nextIndex], t[prevIndex] ] = [ t[prevIndex], t[nextIndex]]
        setSteps(t);
    }

    function clearStep() {
        setStepDescription('');
        setStepIndex(null);
        setFileTempStepCover(null);
    }

    function checkExercise() {
        if (name === '') {
            toast.error('Не заполнено название');
            return false;
        }

        return true;
    }

    function checkStep() {
        if (fileTempStepCover === null && stepDescription === '') {
            toast.error('Нужно выбрать изображение или заполнить описание шага')
            return false;
        }

        return true;
    }

    async function create() {
        if (!checkExercise()) {
            return;
        }

        let coverId = null;
        let data = new FormData();
        if (fileCover !== null) {
            coverId = v4();
            data.append('file',fileCover);
            data.append('id', coverId);

            try {
                const response = await props.store.Images.upload(data);
                if (!response.data.status) {
                    toast.error(response.data.message);
                    return;
                }
            }
            catch (error) {
                toast.error(error?.response?.data?.message || error.message);
                return;
            };
        }
        
        data = new FormData();
        data.append('name', name);
        data.append('description', description);
        data.append('image_id', coverId);
        
        try {
            const response = await props.store.Exercises.create(data);
            if (!response.data.status) {
                toast.error(response.data.message);
                return;
            }

            const exerciseId = response.data.data.id;
            let indx = 0;
            for(let step of steps) {
                coverId = null;
                if (step.image !== null) {
                    data = new FormData();
                    coverId = v4();
                    data.append('file',step.image);
                    data.append('id', coverId);

                    const imageResponse = await props.store.Images.upload(data);
                    if (!imageResponse.data.status) {
                        toast.error(imageResponse.data.message);
                        return;
                    }
                }

                data = new FormData();
                data.append('description', description);
                data.append('image_id', coverId);
                data.append('order_number', indx);
                data.append('exercise_id', exerciseId);

                const stepResponse = await props.store.ExerciseItems.create(data);
                if (!stepResponse.data.status) {
                    toast.error(stepResponse.data.message);
                    return;
                }

                indx++;
            }
        }
        catch (error) {
            toast.error(error?.response?.data?.message || error.message);
            return;
        }

        props.store.Exercises.loadingData();
        props.store.Images.loadingData();
        navigate('/exercises');
    }

    return <Component>
        <h2>Основная информация</h2>
        <span>Давай заполним основую информацию</span>
        <Input type='text' value={name} label='Название' onChange={(event => setName(event.target.value))}/>
        <Textarea type='text' value={description} rows='3' label='Описание' onChange={(event => setDescription(event.target.value))}/>
        <span>Также можно добавить обложку</span>
        { fileCover !== null && <Image centered data={fileCover}/> }
        <Centered>
            <InputContainer>
                <ImageInput type='file' id='cover' onChange={(event) => {setImagePreview(event, setFileCover)}}/>
                <label for='cover'>Выбрать обложку</label>
            </InputContainer>
            { previewCover !== null && <Button type='delete' onClick={() => setFileCover(null)}>Удалить обложку</Button> }
        </Centered>
        {
            !showStepSection && <>
                <span>Если все готово, то можно создать упражнение прямо сейчас или добавить шаги</span>
                <Centered>
                    <Button onClick={() => create()}>Создать</Button>
                    <span>или</span>
                    <Button onClick={() => setShowStepSection(true)}>Продолжить</Button>
                </Centered>
            </>
        }
        {
            showStepSection && <>
                <h2>Добавить шаг</h2>
                <span>Здесь ты можешь добавить шаги или изображения</span>
                    { fileTempStepCover !== null && <Image centered data={fileTempStepCover}/> }
                    <Centered>
                        <InputContainer>
                            <ImageInput type='file' id='cover1' onChange={(event) => setImagePreview(event, setFileTempStepCover)}/>
                            <label for='cover1'>Выбрать изображение</label>
                        </InputContainer>
                        { previewTempStepCover !== null && <Button type='delete' onClick={() => setFileTempStepCover(null)}>Удалить</Button> }
                    </Centered>
                <Textarea type='text' value={stepDescription} rows='5' label='Описание действий' onChange={(event => setStepDescription(event.target.value))}/>
                <Centered>
                    <Button onClick={() => stepIndex !== null ? updateStep() : addStep()}>{stepIndex !== null ? 'Изменить' : 'Добавить'} шаг</Button>
                    { stepIndex !== null && <Button onClick={() => clearStep()}>Отмена</Button> }
                </Centered>
                {
                    steps.length > 0 && <>
                        <h2>Шаги упражнения</h2>
                        <StepList>
                        {
                            steps.map((step, index) => <StepSection key={'step_'+index}>
                                <div>
                                    <StepTitle>Шаг #{index+1}</StepTitle>
                                    { index !== 0 && <EmojiButton onClick={() => changeStepOrder(index, index - 1)}>⬆️</EmojiButton> }
                                    { index + 1 !== steps.length && <EmojiButton onClick={() => changeStepOrder(index, index + 1)}>⬇️</EmojiButton> }
                                    <EmojiButton onClick={() => setStep(index, step)}>✏️</EmojiButton>
                                    <EmojiButton onClick={() => deleteStep(index)}>❌</EmojiButton>
                                </div>
                                {step.image && <Image data={step.image}/>}
                                <span style={{fontSize: '0.9rem', fontStyle: 'italic'}}>{step.description}</span>
                            </StepSection>)
                        }
                        </StepList>
                    </>
                }
                <Centered>
                    <Button onClick={() => create()}>Создать</Button>
                </Centered>
            </>
        }
        
    </Component>;
}

export default  inject("store")(CreateExercise);