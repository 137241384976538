import styled from "styled-components";
import { Colors } from "../constants";

const Component = styled.div`
    flex-direction: row;
    gap: .5rem;
    display: flex;
    border: 1px solid lightgrey;
    border-radius: .25rem;
    padding: .5rem;
    background-color: white;
`;

export default function Weight({data}) {
    const dateFormat = new Intl.DateTimeFormat('ru-ru');
    const diffFormat = new Intl.NumberFormat('en', { minimumFractionDigits: 0, maximumFractionDigits: 2})

    return <Component>
        <span>{dateFormat.format(new Date(data.createdAt))}</span>
        <span>{data.weight}кг</span>
        {
            data.diff !== 0 && <span style={{color: data.diff > 0 ? Colors.red : 'green'}}>{data.diff > 0 && '+'}{diffFormat.format(data.diff)}</span>
        }
    </Component>
}