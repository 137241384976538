import styled, { keyframes} from "styled-components"
import { Colors } from "../../constants";
import Image from "../../components/image_preview";
const anim = keyframes`
    from {
        filter: blur(200px);
    }
    to {
        filter: blur(4px);
    }
`;
const Component = styled.div`
    position: relative;
    margin: auto;
    height: 100%;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
`;


export default function Welcome(props) {
    return <Component>
        <h1 style={{fontSize: '4rem'}}>{props.data.name}</h1>
        {props.data.image && <Image data={props.data.image?.path} height='60%' width='auto'/> }
        
        <p style={{fontStyle: 'italic'}}>{props.data.description}</p>
        <span style={{fontWeight: 'bold', fontSize: '.9rem'}}>Количество упражнений: {props.data.steps.length}</span>
    </Component>
}