import { useEffect, useState } from "react";
import styled from "styled-components";
import Image from "../../components/image_preview";

const Component = styled.div`
    position: relative;
    align-items: center;
    flex-direction: column;
    display: flex;
    height: 100%;
`;
const List = styled.div`
    flex-direction: row;
    gap: 1rem;
    display: flex;
`;

function Exercise(props) {
    const [data, setData] = useState(null);
    useEffect(() => {
        setData(props.data);
    }, [props])
    return <Component>
        <h1 style={{fontSize: '4rem'}}>{data?.name}</h1>
        <p>{data?.description || data?.exercise?.description}</p>
        <List>
        {
            data?.exercise?.steps?.map((step, index) => <div key={step.id} style={{display: "flex", flex: 1, flexDirection: 'column'}}>
                {step.image !== null && <Image centered data={step.image.path} width='50%' height='auto'/> }
                <span>{step.description}</span>
            </div>)
        }
        </List>
    </Component>;
}

export default Exercise;