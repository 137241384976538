import ReactDOM from 'react-dom/client';
import { Provider } from "mobx-react";
import App from './App';
import styled, { createGlobalStyle } from 'styled-components';
import Global from './store/global';
import Workouts from './store/workouts';
import WorkoutItems from './store/workout_items';
import Images from './store/images';
import Exercises from './store/exercises';
import ExerciseItems from './store/exercise_items';
import { configure } from "mobx"
import User from './store/user';
import Main from './pages/main';
import Workouts1 from './pages/workouts';
import ExercisesP from './pages/exercises';
import ErrorPage from "./pages/error";
import Diaries from './pages/diaries';
import { Routes, Route, Switch, createBrowserRouter,  RouterProvider, createRoutesFromElements } from "react-router-dom";
import CreateExercise from './pages/create_exercise';
import Exercise from './pages/exercise';
import EditExercise from './pages/edit_exercise';
import CreateWorkout from './pages/create_workout';
import WorkoutPage from './pages/workout';
import EditWorkoutPage from './pages/edit_workout';
import TrainingPage from './pages/training';
import Diary from './store/diaries';
import Weights from './store/weights';
import { dataStore } from './store/data';
import DiaryP from './pages/diary';

// don't allow state modifications outside actions
setTimeout(() =>
  configure({
    reactionScheduler: (f) => setTimeout(f),
  }),
)
// configure({
//     useProxies: "never"
// })

const GlobalStyle = createGlobalStyle`
	html {
		height: 100%;
	}
	body {
		height: 100%;
		margin: 0;
		font-family: 'Roboto', sans-serif;

		*, ::before, ::after {
			box-sizing: border-box;
		}
	}
	div#root {
		height: 100%;
	}
`;
const Page = styled.div`
	height: 100%;
`;
const stores = {
	Global,
	Workouts,
	WorkoutItems,
	Images,
	Exercises,
	ExerciseItems,
	User,
	Diary,
	Weights,
	dataStore
}
const routes = createBrowserRouter(
    createRoutesFromElements(
        <Route path="/" element={<App/>} errorElement={<ErrorPage/>}>
			<Route index element={<Main/>}/>
			<Route path='/workouts' element={<Workouts1/>} caseSensitive={false}/>
			<Route path='/workout/:id' element={<WorkoutPage />} />
			<Route path="/exercises" element={<ExercisesP/> } />
			<Route path="/exercise/:id" element={<Exercise/>} />
			<Route path='/diaries' element={<Diaries />} />
			<Route path='/diary/:id' element={<DiaryP />} />
			<Route path='/create-exercise' element={<CreateExercise /> }/>
			<Route path='/edit-exercise/:id' element={<EditExercise /> }/>
			<Route path='/create-workout' element={<CreateWorkout />}/>
			<Route path='/edit-workout/:id' element={<EditWorkoutPage />} />
			<Route path='/training/:id' element={<TrainingPage />} />
        </Route>
    )
);

ReactDOM.createRoot(document.getElementById("root")).render(
	<Provider store={stores}>
		<GlobalStyle />
		<RouterProvider router={routes} />
	</Provider>
);
